import { Component, OnInit } from '@angular/core';
import {VoipChargeGroup} from '../../../../../../../models/voip-charge-group';
import {Table} from '../../../../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {ActivatedRoute} from '@angular/router';
import { VoipPhone } from '../../../../../../../models/voip-phone';
import {VoipPhoneService} from '../../../../../../../services/voip/phone/voip-phone.service';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.css']
})
export class PhoneComponent implements OnInit {

  currentInstanceId = null;

  newObject = new VoipChargeGroup();

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: {
      pageNumber: 0,
      size: 10,
      sortColumn: 'name',
      sortOrder: 'ASC',
      totalElements: 0,
      totalPages: 0
    },
    loading: false,
    columnList: [
      {name: 'Name', prop: 'name'},
      {name: 'Extension', prop: 'extension'},
      {name: 'Mac Address', prop: 'identifier'},
      {name: 'Vendor Product', prop: 'vendorProduct'},
      {name: 'Firmware Current', prop: 'firmwareCurrent'},
      {name: 'Last seen', prop: 'lastSeen'},
      {name: 'Added', prop: 'added'},
    ],
    rowList: new Array<VoipPhone>()
  };

  constructor(private route: ActivatedRoute,
              private voipPhoneService: VoipPhoneService) {
  }

  ngOnInit() {

    this.currentInstanceId = this.route.snapshot.paramMap.get('instanceId');

    this.getPage(0);
  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.voipPhoneService.getAllList(this.currentInstanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

}
