import {Component, OnInit} from '@angular/core';
import {Country} from '../../models/country';
import {CountryService} from '../../services/country/country.service';
import {LegalEntity} from '../../models/legal-entity';
import {ToastrService} from 'ngx-toastr';
import {Locale} from '../../models/locale';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../models/user';
import {MeService} from '../../services/me/me.service';
import {KeycloakProfile} from 'keycloak-js';

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.css']
})
export class MeComponent implements OnInit {

  submitEnabled = true;

  user: User = {
    id: null,
    email: null,
    username: null,
    roleList: null,
    keycloakId: null,
    password: null,
    temporaryPassword: null,
    profile: {
      id: null,
      address: {
        id: null
      },
    },
    createdAt: null,
    updatedAt: null,
    locale: 'en',
    enabled: false,
    emailVerified: false,
    type: null,
    legalEntityList: [],
    hotelList: [],
    roomList: []
  };
  loginInfo: KeycloakProfile;
  localeList = new Array<Locale>();
  countryList = new Array<Country>();
  countryListLoading: boolean;

  constructor(private meService: MeService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
  }

  async ngOnInit() {

    this.localeList = [
      {
        iso: 'de',
        name: 'Deutsch'
      },
      {
        iso: 'en',
        name: 'English'
      }
    ];

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    const userData = await this.authenticationService.getAuthUser.portalInfo;

    if (userData) {
      this.user = await userData;
    }

    this.user.username = await this.authenticationService.getAuthUser.loginInfo.username;
    this.user.email = await this.authenticationService.getAuthUser.loginInfo.email;
    this.user.lastName = await this.authenticationService.getAuthUser.loginInfo.lastName;
    this.user.firstName = await this.authenticationService.getAuthUser.loginInfo.firstName;
  }

  customSearchFn(term: string, item: LegalEntity) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onFormSubmit(value) {

    this.submitEnabled = false;

    this.meService.update(value).subscribe(resp => {

      if (resp.status === 'success') {
        this.toastr.success('Your profile has been successfully updated!');

      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  log() {
    console.dir(this.submitEnabled);
    console.dir('click protection');
  }
}
