import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../models/page';
import {HotelService} from '../../../services/hotel/hotel.service';
import {Hotel} from '../../../models/hotel';
import {ToastrService} from 'ngx-toastr';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {AuthenticationInterface, AuthenticationService} from '../../../services/authentication.service';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';

@Component({
  selector: 'app-list-hotel',
  templateUrl: './list-hotel.component.html',
  styleUrls: ['./list-hotel.component.css']
})
export class ListHotelComponent implements OnInit, OnDestroy, AuthenticationInterface {

  @ViewChild(DatatableComponent, {static: false}) tableDC: DatatableComponent;
  temp = [];

  table: Table = {
    columnMode: ColumnMode.standard,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Business ID', prop: 'businessId'},
      {name: 'Country', prop: 'address.country.name'},
      {name: 'City', prop: 'address.city'}
    ],
    rowList: new Array<Hotel>()
  };

  constructor(private hotelService: HotelService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
    this.table.page.pageNumber = 0;
    this.table.page.size = 10;
    this.table.loading = true;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  getPage(pageNumber) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.page.pageNumber = pageNumber;
    this.hotelService.getList(this.table.page).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.table.page = dataResponse.page;
      }
      // const data = dataResponse.data;
      // this.table.rowList = this.table.rowList.concat(data);

      const data = dataResponse.data;

      this.temp = [...data];
      this.table.rowList = data;
      this.table.loading = false;

    });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  deleteData(row) {

  }

  getDataUrl(id: number) {
    return `${this.hotelService.endPoint}/${id}`;
  }

  getEditUrl(id: number) {
    return `${this.hotelService.endPoint}/${id}/edit`;
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList) {
    this.delete(rowList);
  }

  delete(rowList: Array<Hotel>) {

    const that = this;

    rowList.forEach(function (row) {
      that.hotelService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.country.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.city.toLowerCase().indexOf(val) !== -1 ||
        d.customerNumber.toLowerCase().indexOf(val) !== -1 ||
        d.businessId.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.table.rowList = temp;

    this.tableDC.offset = 0;
  }

  isGranted(role): boolean {
    return this.authenticationService.isGranted(role);
  }
}
