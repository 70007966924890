export enum ModuleTypes {
  VINN_CAST = 'vinn-cast',
  PMS_ORACLE_INTERFACE = 'vinn-pms-oracle-interface',
  PMS_SIHOT_INTERFACE = 'vinn-pms-sihot-interface',
  LG_PCD = 'vinn-lg-pcd',
  NETWORK_DEVICE_INFO = 'vinn-network-device-info',
  OTRUM_INFO = 'vinn-otrum-info',
  OTRUM_PREMISE_API = 'vinn-otrum-premise-api',
  VOIP = 'vinn-voip-api',
  MESSAGE_BROKER = 'vinn-message-broker',
  PORTAL_API = 'vinn-portal-api',
  ELECTRO_DEVICE_INVENTORY = 'vinn-electro-device-inventory'
}

export class ModuleType {
  id?: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
}
