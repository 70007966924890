import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {Chromecast} from '../../models/chromecast';

@Injectable({
  providedIn: 'root'
})
export class ChromecastService {

  endPoint = `/hotels`;

  constructor(private http: HttpClient) {
  }

  getList(hotelId: string, instanceId: string, page: Page): Observable<DataListResponse<Chromecast>> {
    return this.http.get<DataListResponse<Chromecast>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/devices/chromecasts`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }
}
