import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {NetworkDeviceInfo} from '../models/network-device-info';

@Injectable({
  providedIn: 'root'
})
export class NetworkDeviceInfoService {

  endPoint = `/hotels`;

  constructor(private http: HttpClient) {
  }

  getList(hotelId: string, instanceId: string, page: Page): Observable<DataListResponse<NetworkDeviceInfo>> {
    return this.http.get<DataListResponse<NetworkDeviceInfo>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/network-device-infos`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  get(hotelId, networkDeviceInfoId): Observable<DataResponse<NetworkDeviceInfo>> {
    return this.http.get<DataResponse<NetworkDeviceInfo>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/network-device-infos/${networkDeviceInfoId}`
    );
  }

  create(networkDeviceInfo: NetworkDeviceInfo, hotelId: string, instanceId: string) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/network-device-infos`, {
      vlanId: networkDeviceInfo.vlanId,
      ipAddress: networkDeviceInfo.ipAddress,
      deviceTypeId: networkDeviceInfo.deviceType != null ? networkDeviceInfo.deviceType.id : null,
      deviceModelId: networkDeviceInfo.deviceModel != null ? networkDeviceInfo.deviceModel.id : null,
      deviceManufacturerId: networkDeviceInfo.deviceManufacturer != null ? networkDeviceInfo.deviceManufacturer.id : null,
      hostName: networkDeviceInfo.hostName
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(networkDeviceInfo: NetworkDeviceInfo, hotelId: string, instanceId: string): Observable<DataListResponse<NetworkDeviceInfo>> {
    return this.http.put<DataListResponse<NetworkDeviceInfo>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/network-device-infos/${networkDeviceInfo.id}`,
      {
        vlanId: networkDeviceInfo.vlanId,
        ipAddress: networkDeviceInfo.ipAddress,
        deviceTypeId: networkDeviceInfo.deviceType != null ? networkDeviceInfo.deviceType.id : null,
        deviceModelId: networkDeviceInfo.deviceModel != null ? networkDeviceInfo.deviceModel.id : null,
        deviceManufacturerId: networkDeviceInfo.deviceManufacturer != null ? networkDeviceInfo.deviceManufacturer.id : null,
        hostName: networkDeviceInfo.hostName
      }, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      });
  }

  delete(id: number): Observable<DataListResponse<NetworkDeviceInfo>> {
    return this.http.delete<DataListResponse<NetworkDeviceInfo>>(`${HelperService.getAPIUrl('/network-device-infos')}/${id}`);
  }

}
