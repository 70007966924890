import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from '../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../../models/page';
import {AuthenticationService} from '../../../../services/authentication.service';
import {DeviceManufacturer} from '../../../../models/device-manufacturer';
import {DeviceManufacturerService} from '../../../../services/management/device-manufacturer/device-manufacturer.service';
import {ToastrService} from 'ngx-toastr';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';
import {DataListResponse} from '../../../../models/data-list-response';

@Component({
  selector: 'app-list-device-manufacturer',
  templateUrl: './list-device-manufacturer.component.html',
  styleUrls: ['./list-device-manufacturer.component.css']
})
export class ListDeviceManufacturerComponent implements OnInit {

  @ViewChild(DatatableComponent, {static: false}) dataTable: DatatableComponent;

  temp = [];
  createWaiting = false;
  editWaiting = false;
  newObject = new DeviceManufacturer();
  selectedObject: DeviceManufacturer = null;

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'ID', prop: 'id'},
      {name: 'Name', prop: 'name'},
    ],
    rowList: new Array<DeviceManufacturer>()
  };

  createModalOptions = {
    title: 'Add New Device Manufacturer',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  editModalOptions = {
    title: 'Edit Device Manufacturer',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Save',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.editWaiting = true;
        this.update(resolve);
      });
    },
    allowOutsideClick: () => !this.editWaiting
  };

  constructor(private deviceManufacturerService: DeviceManufacturerService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService) {
  }


  ngOnInit() {
    this.getPage(0);
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.deviceManufacturerService
      .create(this.newObject)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.createWaiting = false;

          this.displayMessage(dataResponse);
        },
        error => {
          this.createWaiting = false;
        });
  }
  update(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.deviceManufacturerService
      .update(this.selectedObject)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.editWaiting = false;
        },
        error => {
          this.editWaiting = false;
        });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.table.rowList = temp;

    // this.dataTable.offset = 0;
  }

  delete(rowList) {

    const that = this;

    rowList.forEach(function (row) {

      that.deviceManufacturerService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.page.pageNumber = pageNumber;
    this.deviceManufacturerService.getList(this.table.page).subscribe(dataResponse => {

      this.displayMessage(dataResponse);

      // const data = dataResponse.data;
      // this.table.rowList = this.table.rowList.concat(data);

      const data = dataResponse.data;

      this.temp = [...data];
      this.table.rowList = data;
      this.table.loading = false;

    });
  }

  isGranted(role): boolean {
    return this.authenticationService.isGranted(role);
  }

  edit(row: DeviceManufacturer) {
    this.selectedObject = row;
  }

  private displayMessage(dataResponse: DataListResponse<DeviceManufacturer>) {
    if (dataResponse.status === 'error') {
      this.toastr.error(dataResponse.message, 'Error');
    }

    if (dataResponse.page !== null) {
      this.table.page = dataResponse.page;
    }
  }
}
