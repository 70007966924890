import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from '../../../models/page';
import {ToastrService} from 'ngx-toastr';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {AuthenticationInterface, AuthenticationService} from '../../../services/authentication.service';
import {Activity} from '../../../models/activity';
import {ActivityService} from '../../../services/activity/activity.service';

@Component({
  selector: 'app-list-activities',
  templateUrl: './list-activities.component.html',
  styleUrls: ['./list-activities.component.css']
})
export class ListActivitiesComponent implements OnInit, OnDestroy, AuthenticationInterface {

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'User', prop: 'portalUser.username'}
    ],
    rowList: new Array<Activity>()
  };

  constructor(private activityService: ActivityService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
    this.table.page.pageNumber = 0;
    this.table.page.size = 10;
    this.table.loading = true;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  getPage(pageNumber) {

    this.table.page.pageNumber = pageNumber;
    this.activityService.getList(this.table.page).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.table.page = dataResponse.page;
      }
      const data = dataResponse.data;
      this.table.rowList = this.table.rowList.concat(data);
      this.table.loading = false;
    });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  deleteData(row) {

  }

  getDataUrl(id: number) {
    return `${this.activityService.endPoint}/${id}`;
  }

  getEditUrl(id: number) {
    return `${this.activityService.endPoint}/${id}/edit`;
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList) {
    this.delete(rowList);
  }

  delete(rowList: Array<Activity>) {

    const that = this;

    rowList.forEach(function (row) {
      that.activityService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  isGranted(role): boolean {
    return this.authenticationService.isGranted(role);
  }
}
