import {Injectable} from '@angular/core';
import {TreeItem} from 'ngx-treeview';
import {DataResponse} from '../../models/data-response';
import {HelperService} from '../helper.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionMapService {

  endPoint = `/users/permission-map`;

  constructor(private http: HttpClient) {
  }

  getRoles(): Promise<DataResponse<TreeItem>> {
     return this.http.get<DataResponse<TreeItem>>(`${HelperService.getAPIUrl(this.endPoint)}`).toPromise();
  }
}
