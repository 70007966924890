
export class ElectroDeviceInventoryItem {
  id?: number;
  positionNumber?: string;
  articleNumber?: string;
  barcode?: string;
  deviceType?: string;
  protectionClass?: string;
  controlType?: string;
  manufacturer?: string;
  typeModel?: string;
  serialNumber?: string;
  ibn?: string;
  ce?: string;
  location?: string;
  comment?: string;
  isDGUVV3Passed?: boolean;
  nextDGUVV3?: string;
}
