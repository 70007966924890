import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Instance} from '../../../../../../../models/instance/instance';
import {Hotel} from '../../../../../../../models/hotel';
import {Table} from '../../../../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../../../../../models/page';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {HotelService} from '../../../../../../../services/hotel/hotel.service';
import {Room} from '../../../../../../../models/room';
import {ElectroDeviceInventoryService} from '../../../../../../../services/electro-device-inventory.service';
import {ElectroDeviceInventory} from '../../../../../../../models/electro-device-inventory';
import {DataResponse} from '../../../../../../../models/data-response';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {environment} from '../../../../../../../../environments';
import {Image} from '../../../../../../../models/image';

@Component({
  selector: 'app-electro-device-inventory',
  templateUrl: './electro-device-inventory.component.html',
  styleUrls: ['./electro-device-inventory.component.css']
})
export class ElectroDeviceInventoryComponent implements OnInit {


  @Input() instance: Instance = null;
  @ViewChild('instanceForm', {static: true}) instanceForm;
  createWaiting = false;
  columnMode = ColumnMode;
  hotel: Hotel = null;
  newObject = new ElectroDeviceInventory();
  imageList: any;
  hotelId: string = null;
  instanceId: string = null;

  createModalOptions = {
    title: 'Neues Bestandverzeichnis hinzufügen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
      return new Promise((resolve) => {

        if (this.newObject.fileName != null) {
          this.createWaiting = true;
          this.create(resolve);
          this.createWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Prüfergebnis', prop: 'testResult'}
    ],
    rowList: new Array<ElectroDeviceInventory>()
  };

  optionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Text_Csv],
    disableMultipart: false
  };

  ipAddressValidationMessage: string = null;
  private temp: any;

  constructor(public uploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private electroDeviceInventoryService: ElectroDeviceInventoryService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private hotelService: HotelService) {
  }

  ngOnInit() {

    this.hotelId = this.route.snapshot.paramMap.get('id');
    this.instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.hotelService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.hotel = response.data;
    });
    this.uploader.queue = [];
    this.uploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.uploader.onDropError$.subscribe(
      (err) => {
      });

    this.uploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.uploader.onSuccess$.subscribe(
      (data: any) => {

        this.newObject.fileName = data.body.data.fileName;
        this.instance.imageList = data.body.data;

        data.item.remove();
      }
    );

    this.getPage(0);

  }

  delete(rowList: Array<Room>) {

    const that = this;

    rowList.forEach(function (row) {
      that.electroDeviceInventoryService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  onActivate(event: any) {

  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.iso.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // this.dataTable.offset = 0;
  }

  upload(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadUrl(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.uploader.queue.some((item: FileItem) => item.uploadInProgress);
  }


  getMediaResource(name: string) {
    return environment.apis.backend + '/media/uploads/' + name;
  }

  deleteImage(image: Image) {

  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.electroDeviceInventoryService.getList(hotelId, instanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.temp = data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.electroDeviceInventoryService.create(this.newObject,
      this.route.snapshot.paramMap.get('id'),
      this.route.snapshot.paramMap.get('instanceId')
    ).subscribe((dataResponse: DataResponse<ElectroDeviceInventory>) => {

        this.createWaiting = false;
        this.getPage(0);
        this.newObject = new ElectroDeviceInventory();
        this.getPage(0);

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createWaiting = false;

        if (dataResponse.status === 'success') {
          this.newObject = new ElectroDeviceInventory();
          this.getPage(0);
        }

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }

  view(resolve: (value?: (PromiseLike<any> | any)) => void) {
    alert('view exists');
  }

  removeDocument() {
    this.newObject.fileName = null;
  }

  canCreate() {
    return this.newObject.fileName && this.newObject.testResult && this.newObject.testResultDate && this.newObject.product;
  }
}
