import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
  AppComponent,
  DashboardComponent,
  ListLegalEntityComponent,
  AddLegalEntityComponent,
  EditLegalEntityComponent,
  ViewLegalEntityComponent,
  ListHotelComponent,
  AddHotelComponent,
  EditHotelComponent,
  ViewHotelComponent,
  ListUserComponent,
  AddUserComponent,
  EditUserComponent,
  ViewUserComponent,
  ListModuleComponent,
  AddModuleComponent,
  EditModuleComponent,
  ViewModuleComponent,
  ListDeviceComponent,
  UserComponent,
  HotelComponent,
  LegalEntityComponent,
  ModuleComponent,
  ViewInstanceComponent
} from './components';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AppRoutingModule} from './app-routing.module';
import {initializer} from './utils/app-init';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {DataTablesModule} from 'angular-datatables';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {BreadcrumbModule} from 'angular-crumbs';
import {RoomListComponent} from './components/hotel/view/tabs/room-list/room-list.component';
import {GuestListComponent} from './components/hotel/view/tabs/guest-list/guest-list.component';
import {InstanceListComponent} from './components/hotel/view/tabs/instance-list/instance-list.component';
import {DeviceListComponent} from './components/hotel/view/tabs/device-list/device-list.component';
import {ReportListComponent} from './components/hotel/view/tabs/report-list/report-list.component';
import {ChromecastListComponent} from './components/hotel/view/tabs/view-instance/vinncast/chromecast-list/chromecast-list.component';
import {ConfigurationComponent} from './components/hotel/view/tabs/configuration/configuration.component';
import {NgxUploadModule, MineTypeEnum, DropTargetOptions} from '@wkoza/ngx-upload';
import {FloorComponent} from './components/hotel/view/tabs/floor/floor.component';
import {ErrorComponent} from './components/error/error.component';
import {VinnCastViewComponent} from './components/hotel/view/tabs/view-instance/vinncast/view/view.component';
import {RoomViewComponent} from './components/hotel/view/tabs/room-view/room-view.component';
import {DebounceClickDirective} from './directives/debounce-click.directive';
import {MeComponent} from './components/me/me.component';
import {TreeviewModule} from 'ngx-treeview';
import {ListInstanceComponent} from './components/instance/list/list-instance.component';
import {ListActivitiesComponent} from './components/activities/list/list-activities.component';
import {ListLogsComponent} from './components/logs/list/list-logs.component';
import {KnownIssuesComponent} from './components/known-issues/known-issues.component';
import {DateAgoPipe} from './pipes/date-ago.pipe';

// tslint:disable-next-line:max-line-length
import {NetworkDeviceInfoComponent} from './components/hotel/view/tabs/view-instance/network-device-info/view/network-device-info.component';
import {OtrumInfoComponent} from './components/hotel/view/tabs/view-instance/otrum-info/view/otrum-info.component';
import {ManagementComponent} from './components/management/management.component';
import {ListCountryComponent} from './components/management/tab/list-country/list-country.component';
import {ListUserTypeComponent} from './components/management/tab/list-user-type/list-user-type.component';
import {ListCurrencyComponent} from './components/management/tab/list-currency/list-currency.component';
import {ListTimeZoneComponent} from './components/management/tab/list-time-zone/list-time-zone.component';
import {ListDeviceTypeComponent} from './components/management/tab/list-device-type/list-device-type.component';
import {ListDeviceModelComponent} from './components/management/tab/list-device-model/list-device-model.component';
import {ListDeviceManufacturerComponent} from './components/management/tab/list-device-manufacturer/list-device-manufacturer.component';
import {PmsOracleInterfaceViewComponent} from './components/hotel/view/tabs/view-instance/pms-oracle-interface/view/view.component';
import {PmsSihotInterfaceViewComponent} from './components/hotel/view/tabs/view-instance/pms-sihot-interface/view/view.component';
import {OtrumPremiseApiComponent} from './components/hotel/view/tabs/view-instance/otrum-premise-api/view/otrum-premise-api.component';
import {LgPcdComponent} from './components/hotel/view/tabs/view-instance/lg-pcd/lg-pcd.component';
import {VoipComponent} from './components/hotel/view/tabs/view-instance/voip/view/voip.component';
import {CountryZoneComponent} from './components/hotel/view/tabs/view-instance/voip/country-zone/country-zone.component';
import {ChargeGroupComponent} from './components/hotel/view/tabs/view-instance/voip/charge-group/charge-group.component';
import {PortalApiComponent} from './components/hotel/view/tabs/view-instance/portal-api/portal-api.component';
import {MessageBrokerComponent} from './components/hotel/view/tabs/view-instance/message-broker/message-broker.component';
import {PhoneComponent} from './components/hotel/view/tabs/view-instance/voip/phone/phone.component';
import {PhoneMappingComponent} from './components/hotel/view/tabs/view-instance/voip/phone-mapping/phone-mapping.component';
import {ElectroDeviceInventoryComponent} from './components/hotel/view/tabs/view-instance/electro-device-inventory/list/electro-device-inventory.component';
import {ViewElectroDeviceInventoryComponent} from './components/hotel/view/tabs/view-instance/electro-device-inventory/view/view-electro-device-inventory.component';

export const ngxDropTargetOptions: DropTargetOptions = {
  color: 'dropZoneColor',
  colorDrag: 'dropZoneColorDrag',
  colorDrop: 'dropZoneColorDrop',
  multiple: true,
  accept: [MineTypeEnum.Image, MineTypeEnum.Image_Jpeg]
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ListUserComponent,
    ListHotelComponent,
    AddHotelComponent,
    EditHotelComponent,
    ListLegalEntityComponent,
    ListDeviceComponent,
    AddLegalEntityComponent,
    EditLegalEntityComponent,
    AddUserComponent,
    EditUserComponent,
    ViewLegalEntityComponent,
    ViewHotelComponent,
    ListModuleComponent,
    AddModuleComponent,
    EditModuleComponent,
    ViewUserComponent,
    ViewModuleComponent,
    LegalEntityComponent,
    HotelComponent,
    UserComponent,
    ModuleComponent,
    RoomListComponent,
    GuestListComponent,
    InstanceListComponent,
    DeviceListComponent,
    ReportListComponent,
    ViewInstanceComponent,
    ChromecastListComponent,
    ConfigurationComponent,
    FloorComponent,
    ErrorComponent,
    VinnCastViewComponent,
    PmsOracleInterfaceViewComponent,
    PmsSihotInterfaceViewComponent,
    RoomViewComponent,
    DebounceClickDirective,
    MeComponent,
    ListInstanceComponent,
    ListActivitiesComponent,
    ListLogsComponent,
    KnownIssuesComponent,
    DateAgoPipe,
    NetworkDeviceInfoComponent,
    OtrumInfoComponent,
    ManagementComponent,
    ListCountryComponent,
    ListUserTypeComponent,
    ListCurrencyComponent,
    ListTimeZoneComponent,
    ListDeviceTypeComponent,
    ListDeviceModelComponent,
    ListDeviceManufacturerComponent,
    ListCurrencyComponent,
    ListUserTypeComponent,
    OtrumPremiseApiComponent,
    LgPcdComponent,
    VoipComponent,
    CountryZoneComponent,
    ChargeGroupComponent,
    PortalApiComponent,
    MessageBrokerComponent,
    PhoneComponent,
    PhoneMappingComponent,
    ElectroDeviceInventoryComponent,
    ViewElectroDeviceInventoryComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DataTablesModule,
    NgSelectModule,
    FormsModule,
    NgxDatatableModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary mr-3',
      cancelButtonClass: 'btn btn-dark'
    }),
    BreadcrumbModule,
    NgxUploadModule.forRoot(ngxDropTargetOptions),
    TreeviewModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
