import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-entity',
  templateUrl: './legal-entity.component.html',
  styleUrls: ['./legal-entity.component.css']
})
export class LegalEntityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
