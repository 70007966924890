import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../../../models/page';
import {DataListResponse} from '../../../models/data-list-response';
import {HelperService} from '../../helper.service';
import {VoipChargeGroup} from '../../../models/voip-charge-group';

@Injectable({
  providedIn: 'root'
})
export class VoipChargeGroupService {

  endPoint = '/instances';
  chargeGroupList: Subject<VoipChargeGroup[]> = new Subject<VoipChargeGroup[]>();

  constructor(private http: HttpClient) {

  }

  getList(instanceId: string) {
    this.http.get<DataListResponse<VoipChargeGroup>>(`${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-charge-groups`, {
      params: new HttpParams()
        .set('instanceId', instanceId)
    }).subscribe(dataResponse => {
      this.chargeGroupList.next(dataResponse.data);
    });
  }

  getAllList(instanceId: string, page: Page) {
    return this.http.get<DataListResponse<VoipChargeGroup>>(`${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-charge-groups`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('size', page.size.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('instanceId', instanceId)
    });
  }

  /**
   * @param instanceId
   * @param chargeGroup
   */
  create(instanceId: string, chargeGroup: VoipChargeGroup) {
    console.dir(chargeGroup);
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-charge-groups`, chargeGroup, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(instanceId: string, id: number, value: any): Observable<DataListResponse<VoipChargeGroup>> {
    return this.http.put<DataListResponse<VoipChargeGroup>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-charge-groups/${id}`, value
    );
  }

  delete(instanceId: string, id: number): Observable<DataListResponse<VoipChargeGroup>> {
    return this.http.delete<DataListResponse<VoipChargeGroup>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-charge-groups/${id}`
    );
  }
}
