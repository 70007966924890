import {Module} from '../module';
import {Image} from '../image';
import {ConfigurationInterface} from './configuration-interface';

export enum InstanceCommandType {
  Restart = 'restart',
  ChromecastList = 'chromecast-list',
  Activate = 'activate',
  Deactivate = 'deactivate',
  StreamingList = 'streaming-list',
  PMSDBSwap = 'db-swap',
  PMSLogs = 'pms-logs',
  VoIpUpdatePhones = 'pms-logs'
}

export enum InstanceStatusType {
  Active = 0,
  Deactive = 1,
  Blocked = 2,
  Waiting = 3
}

export class Instance {
  id: number;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  status?: number;
  downloadCode?: string;
  configuration?: ConfigurationInterface;
  module?: Module;
  imageList?: Image[];
}
