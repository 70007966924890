export * from '../app.component';

export * from './dashboard/dashboard.component';

export * from './legal-entity/legal-entity.component';
export * from './legal-entity/add/add-legal-entity.component';
export * from './legal-entity/edit/edit-legal-entity.component';
export * from './legal-entity/list/list-legal-entity.component';
export * from './legal-entity/view/view-legal-entity.component';

export * from './hotel/hotel.component';
export * from './hotel/add/add-hotel.component';
export * from './hotel/edit/edit-hotel.component';
export * from './hotel/list/list-hotel.component';
export * from './hotel/view/view-hotel.component';

export * from './user/user.component';
export * from './user/add/add-user.component';
export * from './user/edit/edit-user.component';
export * from './user/list/list-user.component';
export * from './user/view/view-user.component';

export * from './module/module.component';
export * from './module/add/add-module.component';
export * from './module/edit/edit-module.component';
export * from './module/list/list-module.component';
export * from './module/view/view-module.component';

export * from './device/list/list-device.component';

// HOTEL TABS
export * from './hotel/view/tabs/view-instance/view-instance.component';
