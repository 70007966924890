import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {Device} from '../../models/device';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  endPoint = `/devices`;

  constructor(private http: HttpClient) {}

  getList(page: Page): Observable<DataListResponse<Device>> {
    return this.http.get<DataListResponse<Device>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(id): Observable<DataResponse<Device>> {
    return this.http.get<DataResponse<Device>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(device: Device): Observable<DataListResponse<Device>> {
    return this.http.post<DataListResponse<Device>>(HelperService.getAPIUrl(this.endPoint), device);
  }
  update(id: number, value: any): Observable<DataListResponse<Device>> {
    return this.http.put<DataListResponse<Device>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}/edit`, value);
  }

  delete(id: number): Observable<DataListResponse<Device>> {
    return this.http.delete<DataListResponse<Device>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
