import {Instance} from './instance/instance';

export enum OtrumSignageHardware {
  SoC,
  Cube,
  Monitor,
}

export enum OtrumSignageContent {
  Signage,
  Bonvoy,
  Videowall
}

export class OtrumTvProduct {
  id?: number;
  name?: string;
}

export class OtrumInfo {
  id?: number;
  hotelName?: string;
  tvNeoRouterIp?: string;
  tvProduct?: OtrumTvProduct;
  enterpriseWebUrl?: string;
  webUsername?: string;
  webPassword?: string;
  tizenTvUrl?: string;
  lgTvUrl?: string;
  neoRouterIp?: string;
  neoRouterUsername?: string;
  neoRouterPassword?: string;
  otrumCastServerName?: string;
  signageHardware?: OtrumSignageHardware;
  signageContent?: OtrumSignageContent;
  isInstalled = false;
  instance?: Instance;
}
