import {KeycloakConfig} from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
  url: 'https://auth.myvinn.io/auth',
  realm: 'portal',
  clientId: 'frontend',
  credentials: {
    secret: 'b26a4e6c-6979-4d0f-8ef9-9b1b19baa158'
  }
};

export const environment = {
  production: true,
  assets: {},
  apis: {
    'backend': 'https://api.myvinn.io'
  },
  keycloakConfig
};
