import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {ElectroDeviceInventory} from '../models/electro-device-inventory';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceInventoryService {

  endPoint = `/hotels`;

  constructor(private http: HttpClient) {
  }

  getList(hotelId: string, instanceId: string, page: Page): Observable<DataListResponse<ElectroDeviceInventory>> {
    return this.http.get<DataListResponse<ElectroDeviceInventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/electro-device-inventories`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  get(hotelId, electroDeviceInventoryId): Observable<DataResponse<ElectroDeviceInventory>> {
    return this.http.get<DataResponse<ElectroDeviceInventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/electro-device-inventories/${electroDeviceInventoryId}`
    );
  }

  create(electroDeviceInventory: ElectroDeviceInventory, hotelId: string, instanceId: string) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/electro-device-inventories`, electroDeviceInventory, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(electroDeviceInventory: ElectroDeviceInventory, hotelId: string, instanceId: string): Observable<DataListResponse<ElectroDeviceInventory>> {
    return this.http.put<DataListResponse<ElectroDeviceInventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/electro-device-inventories/${electroDeviceInventory.id}`,
      electroDeviceInventory, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      });
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceInventory>> {
    return this.http.delete<DataListResponse<ElectroDeviceInventory>>(`${HelperService.getAPIUrl('/electro-device-inventories')}/${id}`);
  }

  uploadUrl(hotelId: string, instanceId: string) {
    return `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/electro-device-inventory/upload`;
  }
}
