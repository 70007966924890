import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../../models/data-list-response';
import {HelperService} from '../../helper.service';
import {DataResponse} from '../../../models/data-response';
import {VoipPhoneMapping} from '../../../models/voip-phone-mapping';

@Injectable({
  providedIn: 'root'
})
export class VoipPhoneMappingService {

  endPoint = `/instances`;

  constructor(private http: HttpClient) {
  }

  getList(instanceId: string, page: Page): Observable<DataListResponse<VoipPhoneMapping>> {
    return this.http.get<DataListResponse<VoipPhoneMapping>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-phone-mappings`,
      {
        params: new HttpParams()
          .set('instanceId', instanceId)
          .set('pageNumber', page.pageNumber.toString())
          .set('size', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  get(hotelId, roomId): Observable<DataResponse<VoipPhoneMapping>> {
    return this.http.get<DataResponse<VoipPhoneMapping>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/voip-phone-mappings/${roomId}`
    );
  }

  create(instanceId: string, voipPhoneMapping: VoipPhoneMapping) {
    return this.http.post<DataListResponse<VoipPhoneMapping>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-phone-mappings`, voipPhoneMapping, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }
    );
  }

  delete(instanceId: string, id: number): Observable<DataListResponse<VoipPhoneMapping>> {
    return this.http.delete<DataListResponse<VoipPhoneMapping>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-phone-mappings/${id}`
    );
  }
}
