import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {InstanceInfo} from '../models/instance-info';

@Injectable({
  providedIn: 'root'
})
export class InstanceInfoService {

  endPoint = `/admin/instances`;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Promise<DataListResponse<InstanceInfo>> {
    return this.http.get<DataListResponse<InstanceInfo>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    }).toPromise();
  }
}
