import {Component, OnInit, AfterViewInit, AfterContentInit} from '@angular/core';
import {Instance} from '../../../../../models/instance/instance';
import {InstanceService} from '../../../../../services/instance/instance.service';
import {ActivatedRoute} from '@angular/router';
import {ModuleTypes} from '../../../../../models/module-type';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {BeforeOpenEvent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-view-instance',
  templateUrl: './view-instance.component.html',
  styleUrls: ['./view-instance.component.css']
})
export class ViewInstanceComponent implements OnInit, AfterViewInit, AfterContentInit {

  moduleTypes = ModuleTypes;
  instance: Instance = null;

  constructor(private route: ActivatedRoute,
              private instanceService: InstanceService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.get();
  }

  get() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'));
    this.instanceService.currentInstance.subscribe((instance: Instance) => {
      this.instance = instance;
    });
  }

  isModuleType(type: string) {
    if (this.instance && this.instance.module && this.instance.module.type) {
      return this.instance.module.type.name === type;
    }
  }

  ngAfterContentInit(): void {
    this.instance = null;
  }

  ngAfterViewInit(): void {
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onBeforeOpen($event: BeforeOpenEvent) {

  }
}
