import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {Observable} from 'rxjs';
import {DataResponse} from '../models/data-response';
import {OtrumInfo} from '../models/otrum-info';

@Injectable({
  providedIn: 'root'
})
export class OtrumInfoService {

  endPoint = `/hotels`;

  constructor(private http: HttpClient) {
  }

  getList(hotelId: string, instanceId: string, page: Page): Observable<DataListResponse<OtrumInfo>> {
    return this.http.get<DataListResponse<OtrumInfo>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/otrum-infos`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  get(hotelId, otrumInfoId): Observable<DataResponse<OtrumInfo>> {
    return this.http.get<DataResponse<OtrumInfo>>(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/otrum-infos/${otrumInfoId}`);
  }

  create(otrumInfo: OtrumInfo, hotelId: string, instanceId: string) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/otrum-infos`, {
      hotelName: otrumInfo.hotelName,
      tvProductId: otrumInfo.tvProduct,
      tvNeoRouterIp: otrumInfo.tvNeoRouterIp,
      enterpriseWebUrl: otrumInfo.enterpriseWebUrl,
      webUsername: otrumInfo.webUsername,
      webPassword: otrumInfo.webPassword,
      lgTvUrl: otrumInfo.lgTvUrl,
      tizenTvUrl: otrumInfo.tizenTvUrl,
      neoRouterIp: otrumInfo.neoRouterIp,
      neoRouterUsername: otrumInfo.neoRouterUsername,
      neoRouterPassword: otrumInfo.neoRouterPassword,
      otrumCastServerName: otrumInfo.otrumCastServerName,
      signageHardware: otrumInfo.signageHardware,
      signageContent: otrumInfo.signageContent,
      isInstalled: otrumInfo.isInstalled,
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(otrumInfo: OtrumInfo, hotelId: string, instanceId: string): Observable<DataListResponse<OtrumInfo>> {
    return this.http.put<DataListResponse<OtrumInfo>>(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/otrum-infos/${otrumInfo.id}`, {
      hotelName: otrumInfo.hotelName,
      tvProductId: otrumInfo.tvProduct,
      tvNeoRouterIp: otrumInfo.tvNeoRouterIp,
      enterpriseWebUrl: otrumInfo.enterpriseWebUrl,
      webUsername: otrumInfo.webUsername,
      webPassword: otrumInfo.webPassword,
      lgTvUrl: otrumInfo.lgTvUrl,
      tizenTvUrl: otrumInfo.tizenTvUrl,
      neoRouterIp: otrumInfo.neoRouterIp,
      neoRouterUsername: otrumInfo.neoRouterUsername,
      neoRouterPassword: otrumInfo.neoRouterPassword,
      otrumCastServerName: otrumInfo.otrumCastServerName,
      signageHardware: otrumInfo.signageHardware,
      signageContent: otrumInfo.signageContent,
      isInstalled: otrumInfo.isInstalled,
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  delete(id: number): Observable<DataListResponse<OtrumInfo>> {
    return this.http.delete<DataListResponse<OtrumInfo>>(`${HelperService.getAPIUrl('/otrum-infos')}/${id}`);
  }

}
