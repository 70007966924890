import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {ElectroDeviceInventoryItem} from '../models/electro-device-inventory-item';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceInventoryItemService {

  endPoint = `/hotels`;

  constructor(private http: HttpClient) {
  }

  getList(hotelId: string, instanceId: string, inventoryId: string, page: Page): Observable<DataListResponse<ElectroDeviceInventoryItem>> {
    return this.http.get<DataListResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/electro-device-inventories/${inventoryId}/items`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceInventoryItem>> {
    return this.http.delete<DataListResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl(`/electro-device-inventory-items/${id}`)}`
    );
  }
}
