import {Component, OnInit} from '@angular/core';
import {Table} from '../../../../../models/table';
import {Page} from '../../../../../models/page';
import {Guest} from '../../../../../models/guest';
import {ActivatedRoute} from '@angular/router';
import {SwalComponent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {HotelService} from '../../../../../services/hotel/hotel.service';
import {ColumnMode} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-hotel-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.css']
})
export class GuestListComponent implements OnInit {

  constructor(public readonly swalTargets: SwalPartialTargets,
              private hotelService: HotelService,
              private route: ActivatedRoute) {
  }

  range: string;
  type: number;
  floor: number;

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'ID', prop: 'id'},
      {name: 'Name', prop: 'firstName'},
      {name: 'Last Name', prop: 'lastName'},
      {name: 'Nationality', prop: 'nationality'},
      {name: 'E-Mail Address', prop: 'email'},
    ],
    rowList: new Array<Guest>()
  };

  ngOnInit() {

    this.getPage(0);
  }

  delete(rowList: Array<Guest>) {

  }

  create(form) {

  }

  onActivate($event: any) {

  }

  onSelect($event: any) {

  }

  onSort($event: any) {

  }

  closeCreateModal(x: SwalComponent | ((form) => void)) {

  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.hotelService.getGuestList(this.route.snapshot.paramMap.get('id'), this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

}
