import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Instance} from '../../../../../../../models/instance/instance';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Hotel} from '../../../../../../../models/hotel';
import {Table} from '../../../../../../../models/table';
import {Page} from '../../../../../../../models/page';
import {HttpClientUploadService} from '@wkoza/ngx-upload';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {HotelService} from '../../../../../../../services/hotel/hotel.service';
import {ElectroDeviceInventoryItem} from '../../../../../../../models/electro-device-inventory-item';
import {ElectroDeviceInventoryItemService} from '../../../../../../../services/electro-device-inventory-item.service';

@Component({
  selector: 'app-view-electro-device-inventory',
  templateUrl: './view-electro-device-inventory.component.html',
  styleUrls: ['./view-electro-device-inventory.component.css']
})
export class ViewElectroDeviceInventoryComponent implements OnInit {

  @Input() instance: Instance = null;
  @ViewChild('instanceForm', {static: true}) instanceForm;
  createWaiting = false;
  columnMode = ColumnMode;
  hotel: Hotel = null;
  electroDeviceInventoryItem = new ElectroDeviceInventoryItem();
  imageList: any;
  hotelId: string = null;
  instanceId: string = null;

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Lfd.-<br>Nr.', prop: 'positionNumber'},
      {name: 'Artikel-Nr.', prop: 'articleNumber'},
      {name: 'Barcode', prop: 'barcode'},
      {name: 'Gerätart', prop: 'deviceType'},
      {name: 'Schutz-<br>klasse', prop: 'protectionClass'},
      {name: 'Steuerungstyp', prop: 'controlType'},
      {name: 'Hersteller', prop: 'manufacturer'},
      {name: 'Typ<br>Modell', prop: 'typeModel'},
      {name: 'Serien-Nr.', prop: 'serialNumber'},
      {name: 'IBN', prop: 'ibn'},
      {name: 'CE', prop: 'ce'},
      {name: 'Standort / <br>Zimmer', prop: 'location'},
      {name: 'Bemerkung', prop: 'comment'},
      {name: 'DGUV V3<br>bestanden', prop: 'isDGUVV3Passed'},
      {name: 'nächste<br>DGUV V3', prop: 'nextDGUVV3'},
    ],
    rowList: new Array<ElectroDeviceInventoryItem>()
  };

  private temp: any;

  constructor(public uploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private electroDeviceInventoryItemService: ElectroDeviceInventoryItemService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private hotelService: HotelService) {
  }

  ngOnInit() {

    this.hotelId = this.route.snapshot.paramMap.get('id');
    this.instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.hotelService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.hotel = response.data;
    });

    this.getPage(0);

  }

  delete(rowList: Array<ElectroDeviceInventoryItem>) {

    const that = this;

    rowList.forEach(function (row) {

      that.electroDeviceInventoryItemService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });
    });
  }

  onActivate(event: any) {

  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.iso.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // this.dataTable.offset = 0;
  }


  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');
    const electroDeviceInventoryId = this.route.snapshot.paramMap.get('inventoryId');

    this.electroDeviceInventoryItemService.getList(
      hotelId,
      instanceId,
      electroDeviceInventoryId,
      this.table.page
    ).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.temp = data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }
}
