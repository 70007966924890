import {Component, OnInit} from '@angular/core';
import {LegalEntity} from '../../../models/legal-entity';
import {LegalEntityService} from '../../../services/legal-entity/legal-entity.service';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-add-legal-entity',
  templateUrl: './add-legal-entity.component.html',
  styleUrls: ['./add-legal-entity.component.css']
})
export class AddLegalEntityComponent implements OnInit {

  legalEntity: LegalEntity = {name: null, address: {}};
  submitEnabled = true;

  countryList = new Array<Country>();

  countryListLoading: boolean;

  constructor(private legalEntityService: LegalEntityService,
              private countryService: CountryService,
              private authenticationService: AuthenticationService) {

  }

  ngOnInit() {
    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });
  }

  onCreateFormSubmit(value) {

    this.submitEnabled = false;

    this.legalEntityService.create(value).subscribe(resp => {
      if (resp.status === 'success') {
        this.legalEntity = {name: null, address: {}};
      }
      this.submitEnabled = true;
    });
  }

  customSearchFn(term: string, item: LegalEntity) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  isGranted(role: string) {
    return this.authenticationService.isGranted(role);
  }
}
