import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {Table} from '../../../../../../../models/table';
import {Page} from '../../../../../../../models/page';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {VoipCountryZone} from '../../../../../../../models/voip-country-zone';
import {ToastrService} from 'ngx-toastr';
import {VoipCountryZoneService} from '../../../../../../../services/voip/country-zone/voip-country-zone.service';
import {VoipChargeGroupService} from '../../../../../../../services/voip/charge-group/voip-charge-group.service';

@Component({
  selector: 'app-country-zone',
  templateUrl: './country-zone.component.html',
  styleUrls: ['./country-zone.component.css']
})
export class CountryZoneComponent implements OnInit {

  currentInstanceId = null;

  newObject: VoipCountryZone = new VoipCountryZone();
  voipChargeGroupList = [];

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: {sortColumn: 'id', sortOrder: 'ASC', totalElements: 0, totalPages: 0, size: 10, pageNumber: 0},
    loading: false,
    columnList: [
      {name: '#', prop: 'priority'},
      {name: 'Code', prop: 'code'},
      {name: 'Charge Group', prop: 'chargeGroup.name'}
    ],
    rowList: new Array<VoipCountryZone>()
  };

  createWaiting = false;

  createModalOptions = {
    title: 'Add Country-Zone',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {

        this.createWaiting = true;
        this.create(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  // private SwalError: string;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private voipCountryZoneService: VoipCountryZoneService,
              private toastr: ToastrService,
              private voipChargeGroupService: VoipChargeGroupService) {
  }

  ngOnInit() {

    this.currentInstanceId = this.route.snapshot.paramMap.get('instanceId');
    this.getPage(0);
    this.getChargeGroupList();

  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  delete(rowList: Array<VoipCountryZone>) {

    const that = this;

    rowList.forEach(function (row) {
      that.voipCountryZoneService.delete(that.currentInstanceId, row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.voipCountryZoneService.getList(this.currentInstanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.voipCountryZoneService.create(this.currentInstanceId, this.newObject).subscribe(dataResponse => {

        resolve();
        this.createWaiting = false;
        this.getPage(0);

      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  onBeforeOpen($event: BeforeOpenEvent) {
    this.newObject = new VoipCountryZone();
  }

  private getChargeGroupList() {

    const page: Page = {
      pageNumber: 0,
      totalPages: 0,
      size: 100,
      totalElements: 0,
      sortOrder: 'ASC',
      sortColumn: 'name'
    };

    this.voipChargeGroupService.getAllList(this.currentInstanceId, page).subscribe(value => {
      this.voipChargeGroupList = [...value.data];
    });
  }
}
