import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InstanceService} from '../../../../../../../services/instance/instance.service';
import {ToastrService} from 'ngx-toastr';
import {InstanceCommandType} from '../../../../../../../models/instance/instance';

@Component({
  selector: 'app-instance-voip-view',
  templateUrl: './voip.component.html',
  styleUrls: ['./voip.component.css']
})
export class VoipComponent implements OnInit {

  commandList = InstanceCommandType;
  // public ScreenEditor = Editor;
  // timer;

  @Input() instance = null;
  @ViewChild('instanceForm', {static: true}) instanceForm;

  constructor(private route: ActivatedRoute,
              private instanceService: InstanceService,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(instance => {
        this.instance = instance;
      });
  }

  update() {

    this.instanceService.update(this.route.snapshot.paramMap.get('id'), this.instance).subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success(value.message);
      }

      if (value.status === 'warning') {
        this.toastrService.warning(value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error(value.message);
      }

    });
  }

  sendCommand(command: string) {

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.instanceService.sendCommand(hotelId, instanceId, command, this.instance.clientId, this.instance.secret).subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success('Command has been successfully sent to the instance!');
      }

      if (value.status === 'warning') {
        this.toastrService.warning('Warning in Message Broker! Reason: ' + value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error('Error in Message Broker! Reason: ' + value.message);
      }
    });

    this.refresh();
  }

  isActive() {
    return this.instance.configuration.enabled === true;
  }

  refresh() {
    this.init();
  }

  getPhones() {
    this.sendCommand('retrieve-phones');
  }
}
