import {Component, OnInit} from '@angular/core';
import {Hotel} from '../../../models/hotel';
import {HotelService} from '../../../services/hotel/hotel.service';
import {LegalEntity} from '../../../models/legal-entity';
import {LegalEntityService} from '../../../services/legal-entity/legal-entity.service';
import {CountryService} from '../../../services/country/country.service';
import {Country} from '../../../models/country';
import {Currency} from '../../../models/currency';
import {CurrencyService} from '../../../services/currency/currency.service';
import {TimeZoneService} from '../../../services/time-zone/time-zone.service';
import {LocationService} from '../../../services/location/location.service';
import {TimeZone} from '../../../models/time-zone';
import {first} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-hotel',
  templateUrl: './edit-hotel.component.html',
  styleUrls: ['./edit-hotel.component.css']
})
export class EditHotelComponent implements OnInit {

  businessIdValidationMessage = '';
  businessIdIsValid = true;
  submitEnabled = true;

  hotel: Hotel = {
    name: null,
    address: {},
    legalEntity: {
      name: null,
      address: {}
    }
  };

  legalEntityList = new Array<LegalEntity>();
  countryList = new Array<Country>();
  currencyList = new Array<Currency>();
  timeZoneList = new Array<TimeZone>();

  legalEntityListLoading: boolean;
  countryListLoading: boolean;
  currencyListLoading: boolean;
  timeZoneListLoading: boolean;

  constructor(private hotelService: HotelService,
              private legalEntityService: LegalEntityService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private currencyService: CurrencyService,
              private timeZoneService: TimeZoneService,
              private locationService: LocationService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.loadLegalEntityList();

    this.countryService.getNameList().pipe(first()).subscribe(response => {
      this.countryList = response.data;
    });

    this.currencyService.getList().pipe(first()).subscribe(response => {
      this.currencyList = response.data;
    });

    this.timeZoneService.getList().pipe(first()).subscribe(response => {
      this.timeZoneList = response.data;
    });

    this.hotelService.get(this.route.snapshot.paramMap.get('id')).pipe(first()).subscribe(response => {
      this.hotel = response.data;
    });
  }

  loadLegalEntityList() {
    this.legalEntityListLoading = true;
    this.legalEntityService.getNameList().subscribe(resp => {
      this.legalEntityList = resp.data;
      this.legalEntityListLoading = false;
    });
  }

  onFormSubmit(value) {

    this.submitEnabled = false;

    this.hotelService.update(this.route.snapshot.paramMap.get('id'), value).subscribe(resp => {
      if (resp.status === 'success') {
        this.toastr.success('Hotel has been successfully updated!');
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  customSearchFn(term: string, item: LegalEntity) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  getLocation() {

    this.locationService.getLocation(this.hotel.address).subscribe(resp => {
      const location = resp[0];
      if (location) {
        this.hotel.address.longitude = location.lon;
        this.hotel.address.latitude = location.lat;
      }
    });
  }

  validateBusinessId(event) {

    this.businessIdIsValid = false;
    this.businessIdValidationMessage = this.hotelService.validateBusinessId(event);
    this.businessIdIsValid = this.businessIdValidationMessage === null;
  }
}
