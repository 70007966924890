import {Component, OnInit} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {HotelService} from '../../../../../services/hotel/hotel.service';
import {ActivatedRoute} from '@angular/router';
import {Table} from '../../../../../models/table';
import {Page} from '../../../../../models/page';
import {Report} from '../../../../../models/report';
import {ColumnMode} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-hotel-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {

  constructor(private hotelService: HotelService,
              private route: ActivatedRoute) {
  }

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'ID', prop: 'id'},
      {name: 'Name', prop: 'name'},
      {name: 'Status', prop: 'status'},
    ],
    rowList: new Array<Report>()
  };

  ngOnInit() {

    this.getPage(0);
  }

  delete(rowList: Array<Report>) {

  }

  create(form) {

  }

  onActivate($event: any) {

  }

  onSelect($event: any) {

  }

  onSort($event: any) {

  }

  closeCreateModal(x: SwalComponent | ((form) => void)) {

  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.hotelService.getReportList(this.route.snapshot.paramMap.get('id'), this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }
}
