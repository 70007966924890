import {DeviceType} from './device-type';
import {DeviceModel} from './device-model';
import {DeviceManufacturer} from './device-manufacturer';
import {Instance} from './instance/instance';

export class NetworkDeviceInfo {
  id?: number;
  hostName?: string;
  instance?: Instance;
  deviceType?: DeviceType;
  deviceModel?: DeviceModel;
  deviceManufacturer?: DeviceManufacturer;
  ipAddress?: string;
  vlanId?: string;
  createdAt?: string;
  updatedAt?: string;
  priority?: number;
}
