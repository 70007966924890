import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {Module} from '../../models/module';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  endPoint = `/modules`;

  constructor(private http: HttpClient) {}

    getList(page: Page): Observable<DataListResponse<Module>> {
    return this.http.get<DataListResponse<Module>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('size', page.size.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(id): Observable<DataResponse<Module>> {
    return this.http.get<DataResponse<Module>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(module: Module): Observable<DataListResponse<Module>> {
    return this.http.post<DataListResponse<Module>>(HelperService.getAPIUrl(this.endPoint), module);
  }
  update(id: number, value: any): Observable<DataListResponse<Module>> {
    return this.http.put<DataListResponse<Module>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}/edit`, value);
  }

  delete(id: number): Observable<DataListResponse<Module>> {
    return this.http.delete<DataListResponse<Module>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
