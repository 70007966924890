import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private keycloakService: KeycloakService, private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const currentUser = this.keycloakService.getKeycloakInstance();

    /*
        const minutes = 5;
        const tokenTime = parseInt(localStorage.getItem('token-time'), 10);

        if (tokenTime && (new Date().getTime() - tokenTime > minutes * 60 * 1000)) {
          localStorage.removeItem('token-time');
          localStorage.removeItem('token');
        }
    */

    const user = this.authenticationService.getAuthUser;

    // let isAllowed = true;

    if (user.ssoRoles.includes('ROLE_SUPER_ADMIN')) {
      // isAllowed = true;
    } else if (user.ssoRoles.includes('ROLE_USER') && user.portalInfo) {

      /*isAllowed = user.portalInfo.roleList.some(role => {

        if (this.router.url.startsWith(role.type.endpoint)) {

          if (role.canManageAll) {
            return true;
          }

          switch (request.method) {
            case 'POST':
              return role.canCreate;
            case 'PUT':
              return role.canEdit;
            case 'DELETE':
              return role.canDelete;
            case 'GET':
              return role.canView;
          }

          return false;
        }
      });*/
    }

    // TODO: In zukunft kann man isAllowed in der if condition benutzt werden aber dafür muss ich noch,
    // alle endpoints die raus gehen von der Frontend managen.

    if (currentUser && currentUser.authenticated) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${currentUser.token}`,
          // 'X-Crypto-Security': 'true'
        }
      });
    }

    return next.handle(request);
  }
}
