import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {User} from '../../../models/user';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {LegalEntity} from '../../../models/legal-entity';
import {ToastrService} from 'ngx-toastr';
import {RoleType} from '../../../models/role-type';
import {UserType} from '../../../models/user-type';
import {UserTypeService} from '../../../services/user-type/user-type.service';
import {Locale} from '../../../models/locale';
import {ActivatedRoute} from '@angular/router';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {PermissionMapService} from '../../../services/role/permission-map.service';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  submitEnabled = true;

  user: User = {
    id: null,
    email: null,
    username: null,
    roleList: null,
    keycloakId: null,
    password: null,
    temporaryPassword: null,
    profile: {
      id: null,
      address: {
        id: null
      },
    },
    createdAt: null,
    updatedAt: null,
    locale: 'en',
    enabled: false,
    emailVerified: false,
    type: null,
    legalEntityList: [],
    hotelList: [],
    roomList: []
  };

  localeList = new Array<Locale>();

  countryList = new Array<Country>();
  countryListLoading: boolean;

  userTypeList = new Array<UserType>();
  userTypeListLoading: boolean;

  roleTypeList: Array<RoleType>;

  items: TreeviewItem[];
  values: string[];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: -1
  });

  constructor(private userService: UserService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private roleService: PermissionMapService,
              private userTypeService: UserTypeService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {

    this.roleService.getRoles().then(value => {
      this.items = [new TreeviewItem(value.data)];
    });

    this.localeList = [
      {
        iso: 'de',
        name: 'Deutsch'
      },
      {
        iso: 'en',
        name: 'English'
      }
    ];

    this.userService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.user = response.data.portalInfo;
      this.user.username = response.data.loginInfo.username;
      this.user.email = response.data.loginInfo.email;
      this.user.firstName = response.data.loginInfo.firstName;
      this.user.lastName = response.data.loginInfo.lastName;
      this.user.enabled = response.data.loginInfo.enabled;
      this.user.emailVerified = response.data.loginInfo.emailVerified;
    });

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    this.userTypeService.getList().subscribe(response => {
      this.userTypeList = response.data;
    });
  }

  customSearchFn(term: string, item: LegalEntity) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onFormSubmit(value) {

    this.submitEnabled = false;

    this.userService.update(this.route.snapshot.paramMap.get('id'), value).subscribe(resp => {
      if (resp.status === 'success') {
        this.toastr.success('User has been successfully updated!');
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  log() {
    console.dir(this.submitEnabled);
    console.dir('click protection');
  }

  onFilterChange(value: string) {

  }

  onSelectChange($event: any[]) {
    console.dir($event);
  }

  isGranted(role: string) {
    return this.authenticationService.isGranted(role);
  }
}
