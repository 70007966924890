import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../models/page';
import {ToastrService} from 'ngx-toastr';
import {Activity} from '../../models/activity';
import {TimerObservable} from 'rxjs-compat/observable/TimerObservable';
import {DashboardService} from '../../services/dashboard.service';
import {Dashboard} from '../../models/dashboard';
// @ts-ignore
import {} from '@types/googlemaps';
import {HotelService} from '../../services/hotel/hotel.service';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  map: google.maps.Map;
  lat = 51.3450328;
  lng = 6.5431;

  timer;
  timer2;
  page = new Page();
  activityList = new Array<Activity>();
  dashboard = new Dashboard();

  iconBase = '/assets/images/';

  constructor(/*private activityService: ActivityService,*/
              private toastr: ToastrService,
              private dashboardService: DashboardService,
              private hotelService: HotelService,
              private authenticationService: AuthenticationService) {
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
    // this.timer2.unsubscribe();
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  ngOnInit() {
    this.getDashboard();
    this.getActivities();
    this.getHotels();
  }

  mapInitializer() {

    const coordinates = new google.maps.LatLng(this.lat, this.lng);
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 8,
    };

    this.map = new google.maps.Map(this.gmap.nativeElement,
      mapOptions);
  }

  markerHandler(marker: google.maps.Marker) {
    alert('Marker\'s Title: ' + marker.getTitle());
  }

  getDashboard() {

    this.timer = TimerObservable.create(0, 30000)
      .pipe()
      .subscribe(() => {
        this.dashboardService.get().subscribe(dataResponse => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message, 'Error');
          }

          this.dashboard = dataResponse.data;
        });
      });

  }

  getHotels() {

    const page: Page = {
      pageNumber: 0,
      size: 100,
      totalPages: 0,
      totalElements: 0,
      sortOrder: 'DESC',
      sortColumn: 'id'
    };

    this.hotelService.getList(page).subscribe(dataResponse => {

      for (const hotel of dataResponse.data) {

        const contentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          '</div>' +
          '<h2 id="firstHeading" class="firstHeading" routerLinkActive="active" ><a href="/hotels/' + hotel.id + '" target="_blank">' + hotel.name + '</a></h2>' +
          '<div id="bodyContent"><br>' +
          '<p>' + hotel.address.primary + '<br>' + hotel.address.zipCode + ' ' + hotel.address.city +
          '<br>' + hotel.address.province + ' ' + hotel.address.country.name + '</p>' +
          '</div>';

        const infoWindow = new google.maps.InfoWindow({
          content: contentString
        });

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(+hotel.address.latitude, +hotel.address.longitude),
          map: this.map,
          icon: this.iconBase + 'hotel-marker.png',
          title: hotel.name
        });

        marker.addListener('click', () => {
          infoWindow.open(this.map, marker);
        });
      }
    });
  }

  getActivities() {
    /*
        const page: Page = {
          pageNumber: 0,
          size: 20,
          totalPages: 0,
          totalElements: 0,
          sortOrder: 'DESC',
          sortColumn: 'id'
        };

            this.timer2 = TimerObservable.create(0, 10000)
              .pipe()
              .subscribe(() => {
                this.activityService.getList(page).subscribe(dataResponse => {

                  if (dataResponse.status === 'error') {
                    this.toastr.error(dataResponse.message, 'Error');
                  }

                  this.activityList = dataResponse.data;

                });
              });

         */
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }
}
