import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from '../../../models/page';
import {ToastrService} from 'ngx-toastr';
import {ModuleService} from '../../../services/module/module.service';
import {Module} from '../../../models/module';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {ModuleType} from '../../../models/module-type';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-list-module',
  templateUrl: './list-module.component.html',
  styleUrls: ['./list-module.component.css']
})
export class ListModuleComponent implements OnInit, OnDestroy {

  SwalError: string;

  createWaiting = false;
  newModule = new Module();

  createModalOptions = {
    title: 'Create Instance',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
    ],
    rowList: new Array<Module>()
  };

  constructor(private moduleService: ModuleService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
    this.table.page.pageNumber = 0;
    this.table.page.size = 20;
    this.table.loading = true;

    this.newModule.type = new ModuleType();
    this.newModule.type.id = 1;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.moduleService
      .create(this.newModule)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.createWaiting = false;
        },
        error => {
          this.SwalError = error.toString();
          this.createWaiting = false;
        });
  }

  getPage(pageNumber) {

    this.table.page.pageNumber = pageNumber;
    this.moduleService.getList(this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  onActivate(event) {

  }

  onSelect(event) {

  }

  delete(rowList: Array<Module>) {

    const that = this;

    rowList.forEach(function (row) {
      that.moduleService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  isGranted(role: string) {
    return this.authenticationService.isGranted(role);
  }
}
