import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {Table} from '../../../../../../../models/table';
import {Page} from '../../../../../../../models/page';
import {Chromecast} from '../../../../../../../models/chromecast';
import {ChromecastService} from '../../../../../../../services/chromecast/chromecast.service';
import {ColumnMode} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-chromecast-list',
  templateUrl: './chromecast-list.component.html',
  styleUrls: ['./chromecast-list.component.css']
})
export class ChromecastListComponent implements OnInit {

  // @ViewChild('createModal', {read: true, static: false}) private createSwal: SwalComponent;

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Name', prop: 'name'},
      {name: 'MAC Addr.', prop: 'macAddress'},
      {name: 'IP Addr.', prop: 'ipAddress'},
      {name: 'SSID', prop: 'ssid'},
      {name: 'Signal', prop: 'signalLevel', sortable: false},
      {name: 'Noise', prop: 'noiseLevel', sortable: false},
      {name: 'Last Update', prop: 'lastUpdate'},
      {name: 'Status'},
    ],
    rowList: new Array<Chromecast>()
  };

  // private SwalError: string;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private chromecastService: ChromecastService) {
  }

  ngOnInit() {
    this.getPage(0);
  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.chromecastService.getList(hotelId, instanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }
}
