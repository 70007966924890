import {DeviceManufacturer} from './device-manufacturer';

export class DeviceModel {
  id?: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  priority?: number;
  manufacturer?: DeviceManufacturer;
}
