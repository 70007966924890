import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from '../../../models/page';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../services/authentication.service';
import {InstanceInfo} from '../../../models/instance-info';
import {InstanceInfoService} from '../../../services/instance-info.service';

@Component({
  selector: 'app-list-instance',
  templateUrl: './list-instance.component.html',
  styleUrls: ['./list-instance.component.css']
})
export class ListInstanceComponent implements OnInit, OnDestroy {

  loading: boolean;
  selected = [];
  page = new Page();
  rowList = new Array<InstanceInfo>();
  columnList = [
    {name: 'Module', prop: 'moduleName'}
  ];

  constructor(private instanceInfoService: InstanceInfoService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  getPage(pageNumber) {
    this.loading = true;
    this.page.pageNumber = pageNumber;
    this.instanceInfoService.getList(this.page).then(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.page = dataResponse.page;
      }

      const data = dataResponse.data;

      this.rowList = [...data];
      this.loading = false;

    });
  }

  onSort(event) {
    this.page.sortColumn = event.column.prop;
    this.page.sortOrder = event.newValue;
    this.getPage(0);
  }
}
