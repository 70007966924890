import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LegalEntity} from '../../../models/legal-entity';
import {LegalEntityService} from '../../../services/legal-entity/legal-entity.service';
import {Page} from '../../../models/page';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../services/authentication.service';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';

@Component({
  selector: 'app-list-legal-entity',
  templateUrl: './list-legal-entity.component.html',
  styleUrls: ['./list-legal-entity.component.css']
})
export class ListLegalEntityComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;
  temp = [];

  loading: boolean;
  selected = [];
  page = new Page();
  rowList = new Array<LegalEntity>();
  columnList = [
    {name: 'Name', prop: 'name'},
    {name: 'Country', prop: 'address.country.name'},
    {name: 'City', prop: 'address.city'},
    {name: 'Address', prop: 'address.primary'}
  ];

  constructor(private legalEntityService: LegalEntityService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }


  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  getPage(pageNumber) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.loading = true;
    this.page.pageNumber = pageNumber;
    this.legalEntityService.getList(this.page).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.page = dataResponse.page;
      }

      const data = dataResponse.data;

      this.temp = [...data];
      this.rowList = data;
      this.loading = false;

    });
  }

  onSort(event) {
    this.page.sortColumn = event.column.prop;
    this.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.country.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.city.toLowerCase().indexOf(val) !== -1 ||
        d.address.primary.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rowList = temp;

    this.table.offset = 0;
  }

  delete(rowList) {

    const that = this;

    rowList.forEach(function (row) {

      that.legalEntityService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.rowList = that.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
}
