import {ModuleType} from './module-type';

export class Module {
  id: number;
  name?: string;
  status?: number;
  version?: string;
  createdAt?: string;
  updatedAt?: string;
  configuration?: string;
  description?: string;
  type?: ModuleType;
}
