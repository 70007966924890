import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {Subject} from 'rxjs';
import {Page} from '../../models/page';
import {DeviceModel} from '../../models/device-model';

@Injectable({
  providedIn: 'root'
})
export class DeviceModelService {

  endPoint = `/device-models`;

  list:  Subject<DeviceModel[]> = new Subject<DeviceModel[]>();

  constructor(private http: HttpClient) {
  }

  getList(id: string, page: Page) {
    this.http.get<DataListResponse<DeviceModel>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    }).subscribe(dataResponse => {
      this.list.next(dataResponse.data);
    });
  }

  getOtrumList(id: string, page: Page) {
    return this.http.get<DataListResponse<DeviceModel>>(`${HelperService.getAPIUrl('/otrum-device-models')}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }
}
