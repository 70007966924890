import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {AuthUser} from '../models/auth-user';
import {Router} from '@angular/router';
import {MeService} from './me/me.service';

export declare interface AuthenticationInterface {

  isGranted(role): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<AuthUser>;

  constructor(
    private keycloakService: KeycloakService, private meService: MeService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<AuthUser>(null);
  }

  get getAuthUser(): AuthUser {
    return this.currentUserSubject.value;
  }

  async authenticate() {

    let user: AuthUser = new AuthUser();

    if (await this.keycloakService.isLoggedIn()) {

      user = {
        loginInfo: await this.keycloakService.loadUserProfile(),
        ssoRoles: await this.keycloakService.getUserRoles(true),
        portalInfo: null
      };

      this.registerUser(user);

      if (user.loginInfo && !user.portalInfo) {

        await this.meService.get().then(value => {
          user.portalInfo = value.data;
          this.registerUser(user);
        });

        if (!user.portalInfo) {
          this.router.navigateByUrl('/me');
        }

        this.registerUser(user);
      }
    }

    return user;
  }

  registerUser(user) {
    this.currentUserSubject.next(user);
  }

  logout() {
    // this.keycloakService.clearToken();
    this.keycloakService.logout();
    // localStorage.clear();
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {

    const user = this.getAuthUser;

    if (user.ssoRoles.includes('ROLE_SUPER_ADMIN')) {
      return true;
    }

    const rolePermission = user.ssoRoles.includes(role);

    if (rolePermission && user.portalInfo) {
    }

    return user.ssoRoles.includes(role);
  }
}
