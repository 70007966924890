import {ElectroDeviceInventoryItem} from './electro-device-inventory-item';
import {Hotel} from './hotel';

export class ElectroDeviceInventory {
  id?: number;
  Items?: ElectroDeviceInventoryItem[];
  testResult?: string;
  testResultDate?: string;
  product?: string;
  fileName?: string;
  hotel?: Hotel;
  totalItems?: number;
}
