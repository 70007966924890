import {Component, Input, OnInit} from '@angular/core';
import {Instance, InstanceCommandType} from '../../../../../../models/instance/instance';
import {InstanceService} from '../../../../../../services/instance/instance.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-instance-lg-pcd-view',
  templateUrl: './lg-pcd.component.html',
  styleUrls: ['./lg-pcd.component.css']
})
export class LgPcdComponent implements OnInit {

  commandList = InstanceCommandType;
  @Input() instance: Instance = null;

  constructor(private instanceService: InstanceService,
              private route: ActivatedRoute,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.get();
  }

  get() {
    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
    });
  }

  getAgain() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'));
    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
    });
  }

  update() {
    this.instanceService.update(this.route.snapshot.paramMap.get('id'), this.instance).subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success(value.message);
      }

      if (value.status === 'warning') {
        this.toastrService.warning(value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error(value.message);
      }

    });
  }

  sendCommand(command: string) {

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    // @Todo fix businesId securitytoken
    this.instanceService.sendCommand(hotelId, instanceId, command, '1', '3').subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success('Command has been successfully sent to the instance!');
      }

      if (value.status === 'warning') {
        this.toastrService.warning('Warning in Message Broker! Reason: ' + value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error('Error in Message Broker! Reason: ' + value.message);
      }
    });

    this.refresh();
  }

  refresh() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(instance => {
        this.instance = instance;
      });
  }
}
