import {Component, OnInit} from '@angular/core';
import {Table} from '../../../../../models/table';
import {Page} from '../../../../../models/page';
import {Instance} from '../../../../../models/instance/instance';
import {ActivatedRoute} from '@angular/router';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {HotelService} from '../../../../../services/hotel/hotel.service';
import {InstanceService} from '../../../../../services/instance/instance.service';
import {Module} from '../../../../../models/module';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {ModuleService} from '../../../../../services/module/module.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-hotel-instance-list',
  templateUrl: './instance-list.component.html',
  styleUrls: ['./instance-list.component.css']
})
export class InstanceListComponent implements OnInit {

  createWaiting = false;

  newInstance = new Instance();
  moduleList: Module[];

  createModalOptions = {
    title: 'Create Instance',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {

        this.createWaiting = true;
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
    ],
    rowList: new Array<Instance>()
  };

  hotelId: string;
  moduleName: any;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private hotelService: HotelService,
              private route: ActivatedRoute,
              private instanceService: InstanceService,
              private moduleService: ModuleService,
              private toastr: ToastrService) {
  }

  ngOnInit() {

    this.getPage(0);
    this.hotelId = this.route.snapshot.paramMap.get('id');
    this.getModuleList();
  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  delete(rowList: Array<Instance>) {

    const that = this;

    rowList.forEach(function (row) {
      that.instanceService.delete(that.hotelId, row.id.toString()).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.instanceService
      .create(this.route.snapshot.paramMap.get('id'), this.newInstance)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message, 'Error');
          }

          this.createWaiting = false;
        },
        error => {
          this.createWaiting = false;
        });
  }

  getModuleList() {

    this.moduleService.getList(new Page())
      .subscribe(dataResponse => {
        const data = dataResponse.data;
        this.moduleList = [...data];
      });
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.hotelService
      .getInstanceList(this.route.snapshot.paramMap
        .get('id'), this.table.page)
      .subscribe(dataResponse => {
        this.table.page = dataResponse.page;
        const data = dataResponse.data;
        this.table.rowList = [...data];
        this.table.loading = false;
      });
  }

  isStatus(row: Instance, status) {
    return row.status === status;
  }

  moduleSelected(event: Module) {
     this.newInstance.name = `Instance ${event.name} ${event.version}`;
  }
}
