import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {Table} from '../../../../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {VoipCountryZone} from '../../../../../../../models/voip-country-zone';
import {ToastrService} from 'ngx-toastr';
import {VoipChargeGroupService} from '../../../../../../../services/voip/charge-group/voip-charge-group.service';
import {VoipChargeGroup} from '../../../../../../../models/voip-charge-group';

@Component({
  selector: 'app-charge-group',
  templateUrl: './charge-group.component.html',
  styleUrls: ['./charge-group.component.css']
})
export class ChargeGroupComponent implements OnInit {

  currentInstanceId = null;

  newObject = new VoipChargeGroup();

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: {
      pageNumber: 0,
      size: 10,
      sortColumn: 'name',
      sortOrder: 'ASC',
      totalElements: 0,
      totalPages: 0
    },
    loading: false,
    columnList: [
      {name: 'Name', prop: 'name'},
      {name: 'Charge Amount', prop: 'chargeAmount'},
      {name: 'Time Unit', prop: 'timeUnit'},
    ],
    rowList: new Array<VoipChargeGroup>()
  };

  createWaiting = false;

  createModalOptions = {
    title: 'Add Charge Group',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.create(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  constructor(public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private voipChargeGroupService: VoipChargeGroupService) {
  }

  ngOnInit() {

    this.currentInstanceId = this.route.snapshot.paramMap.get('instanceId');

    this.getPage(0);
  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  delete(rowList: Array<VoipCountryZone>) {

    const that = this;

    rowList.forEach(function (row) {
      that.voipChargeGroupService.delete(that.currentInstanceId, row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });
    });
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.voipChargeGroupService.getAllList(this.currentInstanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.voipChargeGroupService.create(this.currentInstanceId, this.newObject).subscribe(dataResponse => {

        resolve();
        this.createWaiting = false;
        this.getPage(0);

      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  onBeforeOpen($event: BeforeOpenEvent) {
    this.newObject = new VoipChargeGroup();
  }

}
