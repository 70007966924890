import {Injectable} from '@angular/core';
import {Page} from '../../models/page';
import {Subject} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Floor} from '../../models/floor';

@Injectable({
  providedIn: 'root'
})
export class FloorService {

  endPoint = `/hotels`;
  floorList:  Subject<Floor[]> = new Subject<Floor[]>();

  constructor(private http: HttpClient) {

  }

  getList(id: string, page: Page) {
    this.http.get<DataListResponse<Floor>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}/floors`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    }).subscribe(dataResponse => {
      this.floorList.next(dataResponse.data);
    });
  }

  create(hotelId: string, floor: Floor) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/floors`, floor, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }
}
