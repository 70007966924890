import {Component, Input, OnInit} from '@angular/core';
import {InstanceService} from '../../../../../../../services/instance/instance.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {Instance, InstanceCommandType} from '../../../../../../../models/instance/instance';
import {Language} from '../../../../../../../models/instance/pms/language';
import {PMSInterfaceConfiguration} from '../../../../../../../models/instance/pms/configuration';

@Component({
  selector: 'app-instance-pms-sihot-interface-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class PmsSihotInterfaceViewComponent implements OnInit {

  commandList = InstanceCommandType;
  @Input() instance: Instance = null;

  languageMap: Array<Language>;

  constructor(private instanceService: InstanceService,
              private route: ActivatedRoute,
              private toastrService: ToastrService) {
    this.languageMap = [{
      from: '',
      to: ''
    }];

    // Load language map from configuration and update this.languageCount
  }

  ngOnInit() {
    this.get();
  }

  get() {
    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
    });
  }

  getAgain() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'));
    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
    });
  }

  update() {

    // if (this.instance.configuration instanceof PMSInterfaceConfiguration) {

    if (this.instance.configuration.hasOwnProperty('sihot')) {
      const sihotConfiguration: PMSInterfaceConfiguration = this.instance.configuration as PMSInterfaceConfiguration;

      sihotConfiguration.sihotFias.languageMap = [];

      for (const language of this.languageMap) {
        if (language.from !== '' && language.to !== '') {
          sihotConfiguration.sihotFias.languageMap.push(language);
        }

      }

      this.instance.configuration = sihotConfiguration;
    }

    this.instanceService.update(this.route.snapshot.paramMap.get('id'), this.instance).subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success(value.message);
      }

      if (value.status === 'warning') {
        this.toastrService.warning(value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error(value.message);
      }

    });
  }

  sendCommand(command: string) {

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.instanceService.sendCommand(hotelId, instanceId, command, '1', '2').subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success('Command has been successfully sent to the instance!');
      }

      if (value.status === 'warning') {
        this.toastrService.warning('Warning in Message Broker! Reason: ' + value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error('Error in Message Broker! Reason: ' + value.message);
      }
    });

    this.refresh();
  }

  refresh() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(instance => {
        this.instance = instance;
      });
  }
}
