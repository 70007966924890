import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import {AuthUser} from './models/auth-user';
import {NavigationService} from './services/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  user: AuthUser;

  constructor(private authenticationService: AuthenticationService,
              private navigationService: NavigationService) {
  }

  async ngOnInit() {
    await this.authenticationService.authenticate();
    this.user = this.authenticationService.getAuthUser;

  }

  async logout() {
    await this.authenticationService.logout();
  }
  back(): void {
    this.navigationService.back();
  }
  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }
}
