import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-known-issues',
  templateUrl: './known-issues.component.html',
  styleUrls: ['./known-issues.component.css']
})
export class KnownIssuesComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  isGranted(role) {
    return this.authenticationService.isGranted(role);
  }

}
