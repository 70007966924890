import {Component, OnInit} from '@angular/core';
import {Page} from '../../../models/page';
import {Device} from '../../../models/device';
import {DeviceService} from '../../../services/device/device.service';


@Component({
  selector: 'app-list-device',
  templateUrl: './list-device.component.html',
  styleUrls: ['./list-device.component.css']
})
export class ListDeviceComponent implements OnInit {

  loading: boolean;
  selected = [];
  page = new Page();
  rowList = new Array<Device>();

  columnList = [
    {name: 'ID', prop: 'id'},
    {name: 'Name', prop: 'name'}
  ];

  constructor(private deviceService: DeviceService) {
    this.page.pageNumber = 0;
    this.page.size = 20;
    this.loading = true;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  getPage(pageNumber) {

    this.page.pageNumber = pageNumber;
    this.deviceService.getList(this.page).subscribe(dataResponse => {
      this.page = dataResponse.page;
      const data = dataResponse.data;
      this.rowList = [...data];
      this.loading = false;
    });
  }

  onSort(event) {
    this.page.sortColumn = event.column.prop;
    this.page.sortOrder = event.newValue;
    this.getPage(0);
  }
}
