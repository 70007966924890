import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable, ReplaySubject} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {Instance} from '../../models/instance/instance';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';
import {Image} from '../../models/image';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  endPoint = `/hotels`;

  public currentInstance: ReplaySubject<Instance> = new ReplaySubject(1);

  constructor(private http: HttpClient) {
  }

  getList(id: string, page: Page): Observable<DataListResponse<Instance>> {
    return this.http.get<DataListResponse<Instance>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}/instances`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(hotelId: string, instanceId: string) {
    this.http
      .get<DataResponse<Instance>>(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}`)
      .subscribe(dataResponse => {
        const instance: Instance = dataResponse.data;
        instance.configuration = JSON.parse(dataResponse.data.configuration.toString());
        this.currentInstance.next(instance);
      });

    return this.currentInstance;
  }

  sendCommand(hotelId: string, instanceId: string, command: string, businessId: string, securityToken: string) {

    return this.http.post<DataResponse<any>>(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/call`, {
      command: command,
      clientId: businessId,
      clientSecret: securityToken,
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  create(hotelId: string, instance: Instance) {
    return this.http.post<DataResponse<any>>(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances`, {
      name: instance.name,
      configuration: instance.module.configuration,
      moduleId: instance.module.id
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(id: string, instance: Instance): Observable<DataListResponse<Instance>> {

    const url = `${HelperService.getAPIUrl(this.endPoint)}/${id}/instances/${instance.id}`;

    return this.http.put<DataListResponse<Instance>>(url, {
      configuration: instance.configuration,
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  delete(hotelId: string, instanceId: string): Observable<DataListResponse<Instance>> {
    const url = `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}`;
    return this.http.delete<DataListResponse<Instance>>(url);
  }

  uploadUrl(hotelId: string, instanceId: string) {
    return `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/upload`;
  }

  deleteImage(hotelId: string, instanceId: string, image: Image) {
    const url = `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/instances/${instanceId}/images/${image.id}`;
    return this.http.delete<DataListResponse<Image>>(url);
  }
}
