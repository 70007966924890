import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {Table} from '../../../../../../../models/table';
import {Page} from '../../../../../../../models/page';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {NetworkDeviceInfoService} from '../../../../../../../services/network-device-info.service';
import {Instance} from '../../../../../../../models/instance/instance';
import {AuthenticationService} from '../../../../../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {Room} from '../../../../../../../models/room';
import {NetworkDeviceInfo} from '../../../../../../../models/network-device-info';
import {DeviceTypeService} from '../../../../../../../services/device-type/device-type.service';
import {DeviceModelService} from '../../../../../../../services/device-model/device-model.service';
import {DeviceType} from '../../../../../../../models/device-type';
import {DeviceModel} from '../../../../../../../models/device-model';
import {DeviceManufacturer} from '../../../../../../../models/device-manufacturer';
import {DeviceManufacturerService} from '../../../../../../../services/device-manufacturer/device-manufacturer.service';
import {DataResponse} from '../../../../../../../models/data-response';
import {HotelService} from '../../../../../../../services/hotel/hotel.service';
import {Hotel} from '../../../../../../../models/hotel';

@Component({
  selector: 'app-instance-network-device-info-view',
  templateUrl: './network-device-info.component.html',
  styleUrls: ['./network-device-info.component.css']
})
export class NetworkDeviceInfoComponent implements OnInit {

  // @ViewChild('createModal', {read: true, static: false}) private createSwal: SwalComponent;

  @Input() instance: Instance = null;
  createWaiting = false;
  editWaiting = false;

  newObject = new NetworkDeviceInfo();
  selectedObject: NetworkDeviceInfo = null;

  deviceTypeList: DeviceType[];
  deviceModelList: DeviceModel[];
  deviceManufacturerList: DeviceManufacturer[];
  hotel: Hotel = null;
  ipAddressIsValid = false;

  selectedDeviceModelList: DeviceModel[] = [];

  createModalOptions = {
    title: 'Add New Network Device Info',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {

        if (!this.ipAddressIsValid) {
          this.toastr.error('IP Address is not valid!');
          this.createWaiting = false;
          return;
        } else {
          this.createWaiting = true;
          this.create(resolve);
          this.createWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  editModalOptions = {
    title: 'Edit Network Device Info',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Update',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {

        this.editWaiting = true;
        this.update(resolve);
        this.editWaiting = false;
      });
    },
    allowOutsideClick: () => !this.editWaiting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Host Name', prop: 'hostName'},
      {name: 'Device Type', prop: 'deviceType.name'},
      {name: 'Manufacturer', prop: 'deviceModel.manufacturer.name'},
      {name: 'Model', prop: 'deviceModel.name'},
      {name: 'IP Address', prop: 'ipAddress'},
      {name: 'VLAN ID', prop: 'vlanId'},
    ],
    rowList: new Array<NetworkDeviceInfo>()
  };

  // private SwalError: string;
  ipAddressValidationMessage: string = null;
  private temp: any;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private networkDeviceInfoService: NetworkDeviceInfoService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private deviceTypeService: DeviceTypeService,
              private deviceModelService: DeviceModelService,
              private deviceManufacturerService: DeviceManufacturerService,
              private hotelService: HotelService) {
  }

  ngOnInit() {
    this.hotelService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.hotel = response.data;
    });
    this.getDeviceTypeList();
    this.getDeviceManufacturerList();
    this.getDeviceModelList();
    this.getPage(0);
  }

  private getDeviceTypeList() {

    this.deviceTypeService.list.subscribe(value => {
      this.deviceTypeList = value;
    });
  }

  private getDeviceModelList() {
    this.deviceModelService.list.subscribe(value => {
      this.deviceModelList = value;
    });
  }

  private getDeviceManufacturerList() {

    this.deviceManufacturerService.list.subscribe(value => {
      this.deviceManufacturerList = value;
    });
  }

  delete(rowList: Array<Room>) {

    const that = this;

    rowList.forEach(function (row) {
      that.networkDeviceInfoService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  update(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.networkDeviceInfoService
      .update(this.selectedObject, this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.editWaiting = false;
        },
        error => {
          this.editWaiting = false;
        });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.networkDeviceInfoService.create(this.newObject,
      this.route.snapshot.paramMap.get('id'),
      this.route.snapshot.paramMap.get('instanceId')
    ).subscribe((dataResponse: DataResponse<NetworkDeviceInfo>) => {

        this.createWaiting = false;
        this.getPage(0);
        this.newObject = new NetworkDeviceInfo();
        this.getPage(0);

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createWaiting = false;

        if (dataResponse.status === 'success') {
          this.newObject = new NetworkDeviceInfo();
          this.getPage(0);
        }

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  onActivate(event: any) {

  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.iso.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.table.rowList = temp;

    // this.dataTable.offset = 0;
  }


  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.networkDeviceInfoService.getList(hotelId, instanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.temp = data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });

    this.deviceTypeService.getList(this.route.snapshot.paramMap.get('id'), this.table.page);
    this.deviceManufacturerService.getList(this.route.snapshot.paramMap.get('id'), this.table.page);
    this.deviceModelService.getList(this.route.snapshot.paramMap.get('id'), this.table.page);
  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDeviceManufacturerSelect(selectedDeviceManufacturer) {

    this.selectedDeviceModelList = [];

    this.deviceModelList.filter(deviceModel => {
      if (deviceModel.manufacturer.id === selectedDeviceManufacturer.id) {
        this.selectedDeviceModelList.push(deviceModel);
      }
    });
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
    this.ipAddressValidationMessage = '';
    this.ipAddressIsValid = false;
    // this.newObject = new NetworkDeviceInfo();
  }

  edit(row: NetworkDeviceInfo) {
    this.selectedObject = row;
    this.ipAddressValidationMessage = '';
    this.ipAddressIsValid = false;
  }

  validateIpAddress(event) {

    this.ipAddressValidationMessage = '';
    this.ipAddressIsValid = false;

    const splittedIp = event.target.value.toLowerCase().split('.');

    if (splittedIp.length < 4) {
      this.ipAddressValidationMessage = `Given IP is not valid.`;
      return false;
    }

    for (const _block of splittedIp) {

      if (_block === '') {
        this.ipAddressValidationMessage = `Given IP is not valid.`;
        return false;
      }

      const block = Number(_block);

      if (block < 0 || block > 255) {
        this.ipAddressValidationMessage = `Given IP is not valid.`;
        return false;
      }
    }

    this.ipAddressIsValid = true;

    return true;
  }


}
