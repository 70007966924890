import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {Subject} from 'rxjs';
import {Page} from '../../models/page';
import {DeviceType} from '../../models/device-type';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService {

  endPoint = `/device-types`;

  list:  Subject<DeviceType[]> = new Subject<DeviceType[]>();

  constructor(private http: HttpClient) {
  }

  getList(id: string, page: Page) {
    this.http.get<DataListResponse<DeviceType>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    }).subscribe(dataResponse => {
      this.list.next(dataResponse.data);
    });
  }
}
