import {Component, OnInit} from '@angular/core';
import {LegalEntity} from '../../../models/legal-entity';
import {LegalEntityService} from '../../../services/legal-entity/legal-entity.service';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-legal-entity',
  templateUrl: './edit-legal-entity.component.html',
  styleUrls: ['./edit-legal-entity.component.css']
})
export class EditLegalEntityComponent implements OnInit {

  legalEntity: LegalEntity = {name: null, address: {}};
  submitEnabled = true;

  countryList = new Array<Country>();

  countryListLoading: boolean;

  constructor(private legalEntityService: LegalEntityService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    this.legalEntityService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.legalEntity = response.data;
    });
  }

  onCreateFormSubmit(value) {

    this.submitEnabled = false;

    this.legalEntityService.update(this.route.snapshot.paramMap.get('id'), value).subscribe(resp => {
      if (resp.status === 'success') {
        this.toastr.success('Entity has been successfully updated!');
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }
      this.submitEnabled = true;
    });
  }

  customSearchFn(term: string, item: LegalEntity) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }
}
