import { Injectable } from '@angular/core';
import {VoipPhone} from '../../../models/voip-phone';
import {Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../../models/page';
import {DataListResponse} from '../../../models/data-list-response';
import {HelperService} from '../../helper.service';

@Injectable({
  providedIn: 'root'
})
export class VoipPhoneService {

  endPoint = '/instances';
  chargeGroupList: Subject<VoipPhone[]> = new Subject<VoipPhone[]>();

  constructor(private http: HttpClient) {

  }

  getList(instanceId: string) {
    this.http.get<DataListResponse<VoipPhone>>(`${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-phones`, {
      params: new HttpParams()
        .set('instanceId', instanceId)
    }).subscribe(dataResponse => {
      this.chargeGroupList.next(dataResponse.data);
    });
  }

  getAllList(instanceId: string, page: Page) {
    return this.http.get<DataListResponse<VoipPhone>>(`${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-phones`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('size', page.size.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('instanceId', instanceId)
    });
  }
}
