import {Component, Input, OnInit} from '@angular/core';
import {Instance} from '../../../../../../models/instance/instance';
import {InstanceService} from '../../../../../../services/instance/instance.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-message-broker',
  templateUrl: './message-broker.component.html',
  styleUrls: ['./message-broker.component.css']
})
export class MessageBrokerComponent implements OnInit {

  @Input() instance: Instance = null;

  constructor(private instanceService: InstanceService,
              private route: ActivatedRoute,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.get();
  }

  get() {
    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
    });
  }

  getAgain() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'));
    this.instanceService.currentInstance.subscribe(instance => {
      this.instance = instance;
    });
  }

  update() {
    this.instanceService.update(this.route.snapshot.paramMap.get('id'), this.instance).subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success(value.message);
      }

      if (value.status === 'warning') {
        this.toastrService.warning(value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error(value.message);
      }

    });
  }

  refresh() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(instance => {
        this.instance = instance;
      });
  }
}
