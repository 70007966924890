import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {ActivatedRoute} from '@angular/router';
import {InstanceService} from '../../../../../../../services/instance/instance.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../../../../environments';
import {Image} from '../../../../../../../models/image';
import {InstanceCommandType} from '../../../../../../../models/instance/instance';

// import {TimerObservable} from 'rxjs-compat/observable/TimerObservable';

/**import * as Editor from '@ckeditor/ckeditor5-build-inline';**/

@Component({
  selector: 'app-instance-vinncast-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class VinnCastViewComponent implements OnInit, OnDestroy {

  commandList = InstanceCommandType;
  // public ScreenEditor = Editor;
  // timer;
  uploadAllTriggered = false;

  @Input() instance = null;
  @ViewChild('instanceForm', {static: true}) instanceForm;

  optionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png],
    disableMultipart: false
  };

  imageList: any;
  streamingClientList: any;

  constructor(public uploader: HttpClientUploadService,
              private route: ActivatedRoute,
              private instanceService: InstanceService,
              private toastrService: ToastrService) {
  }

  ngOnDestroy(): void {
    // this.timer.unsubscribe();
  }

  ngOnInit() {
    this.init();
    this.uploader.queue = [];
    this.uploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.uploader.onDropError$.subscribe(
      (err) => {
      });

    this.uploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.uploader.onSuccess$.subscribe(
      (data: any) => {
        this.instance.imageList = data.body.data;

        data.item.remove();
      }
    );

    this.uploader.onAddToQueue$.subscribe(
      () => {
        this.instanceForm.reset();
      }
    );
  }

  init() {
    this.instance = null;
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(instance => {
        this.instance = instance;
      });
    /*
            his.timer = TimerObservable.create(0, 10000)
                .pipe()
                .subscribe(() => {
                    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
                        .subscribe(instance => {
                            this.instance = instance;
                        });
                });
    */

  }

  update() {

    this.instanceService.update(this.route.snapshot.paramMap.get('id'), this.instance).subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success(value.message);
      }

      if (value.status === 'warning') {
        this.toastrService.warning(value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error(value.message);
      }

    });
  }

  upload(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.instanceService.uploadUrl(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
    });
  }

  uploadAll() {
    this.uploadAllTriggered = true;
    this.uploader.uploadAll({
      method: 'POST',
      url: this.instanceService.uploadUrl(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.uploader.queue.some((item: FileItem) => item.uploadInProgress);
  }


  getMediaResource(name: string) {
    return environment.apis.backend + '/media/uploads/' + name;
  }

  deleteImage(image: Image) {
    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.instanceService.deleteImage(hotelId, instanceId, image).subscribe(dataResponse => {
      this.instance.imageList = dataResponse.data;
    });
  }

  sendCommand(command: string) {

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.instanceService.sendCommand(hotelId, instanceId, command, '1', '2').subscribe(value => {

      if (value.status === 'success') {
        this.toastrService.success('Command has been successfully sent to the instance!');
      }

      if (value.status === 'warning') {
        this.toastrService.warning('Warning in Message Broker! Reason: ' + value.message);
      }

      if (value.status === 'error') {
        this.toastrService.error('Error in Message Broker! Reason: ' + value.message);
      }
    });

    this.refresh();
  }

  isActive() {
    return this.instance.configuration.active === true;
  }

  refresh() {
    this.instanceService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(instance => {
        this.instance = instance;
      });
  }
}
