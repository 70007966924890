import {Component, OnInit} from '@angular/core';
import {Floor} from '../../../../../models/floor';
import {Page} from '../../../../../models/page';
import {FloorService} from '../../../../../services/floor/floor.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-hotel-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.css']
})
export class FloorComponent implements OnInit {

  newFloor = new Floor();
  floorList: Array<Floor>;
  page: Page;
  createWaiting = false;
  SwalError: any;

  createModalOptions = {
    title: 'Create Floor',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  constructor(private route: ActivatedRoute, private floorService: FloorService) {
    this.page = new Page();
  }

  ngOnInit() {
    this.getFloorList();
  }

  getFloorList() {
    this.floorService.getList(this.route.snapshot.paramMap.get('id'), this.page);

    this.floorService.floorList.subscribe(value => {
      this.floorList = value;
    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.floorService
      .create(this.route.snapshot.paramMap.get('id'), this.newFloor)
      .subscribe(dataResponse => {
          this.getFloorList();
          resolve();
          this.createWaiting = false;
        },
        error => {
          this.SwalError = error.toString();
          this.createWaiting = false;
        });
  }
}
