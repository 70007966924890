import {Injectable} from '@angular/core';
import {LegalEntity} from '../../models/legal-entity';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HelperService} from '../helper.service';
import {Page} from '../../models/page';
import {DataListResponse} from '../../models/data-list-response';
import {DataResponse} from '../../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityService {

  endPoint = `/legal-entities`;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<LegalEntity>> {
    return this.http.get<DataListResponse<LegalEntity>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(id): Observable<DataResponse<LegalEntity>> {
    return this.http.get<DataResponse<LegalEntity>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(legalEntity: LegalEntity): Observable<DataListResponse<LegalEntity>> {
    return this.http.post<DataListResponse<LegalEntity>>(HelperService.getAPIUrl(this.endPoint), legalEntity);
  }

  update(id, value: any): Observable<DataListResponse<LegalEntity>> {
    return this.http.put<DataListResponse<LegalEntity>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`, value);
  }

  delete(id: number): Observable<DataListResponse<LegalEntity>> {
    return this.http.delete<DataListResponse<LegalEntity>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  getNameList(): Observable<DataListResponse<LegalEntity>> {
    return this.http.get<DataListResponse<LegalEntity>>(`${HelperService.getAPIUrl(this.endPoint)}/query/list`, {});
  }
}
