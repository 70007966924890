import {Component, OnInit} from '@angular/core';
import {Hotel} from '../../../models/hotel';
import {HotelService} from '../../../services/hotel/hotel.service';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-view-hotel',
  templateUrl: './view-hotel.component.html',
  styleUrls: ['./view-hotel.component.css']
})
export class ViewHotelComponent implements OnInit {

  id: string;
  hotel: Hotel;
  selected = [];

  constructor(private route: ActivatedRoute,
              private hotelService: HotelService,
              private authenticationService: AuthenticationService,
              private titleService: Title) {

  }

  ngOnInit() {
    this.getHotelView();
  }

  getHotelView() {
    this.hotelService.get(this.route.snapshot.paramMap.get('id')).subscribe(dataResponse => {
      this.hotel = dataResponse.data;
      this.titleService.setTitle( 'VINN Portal - Hotel View of ' + this.hotel.name );
    });
  }

  isGranted(role: string) {
    this.authenticationService.isGranted(role);
  }
}
