import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RoomService} from '../../../../../services/room/room.service';
import {Room} from '../../../../../models/room';

@Component({
  selector: 'app-hotel-room-view',
  templateUrl: './room-view.component.html',
  styleUrls: ['./room-view.component.css']
})
export class RoomViewComponent implements OnInit {

  room: Room = {id: 0};

  constructor(private roomService: RoomService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.roomService.get(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('roomId')).subscribe(response => {
      this.room = response.data;
    });
  }

  send() {
    console.dir('SENt MESSAGE FROM FRONTEND TO PMS INTERFACE...' + this.room);
  }

  roomRightText(type: string) {

    if (type !== '') {
      return this.roomService.getRight(type);
    }

    return '-';
  }
}
