import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {User} from '../../../models/user';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {LegalEntity} from '../../../models/legal-entity';
import {ToastrService} from 'ngx-toastr';
import {PermissionMapService} from '../../../services/role/permission-map.service';
import {UserType} from '../../../models/user-type';
import {UserTypeService} from '../../../services/user-type/user-type.service';
import {Locale} from '../../../models/locale';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  submitEnabled = true;

  user: User = {
    id: null,
    email: null,
    username: null,
    roleList: null,
    keycloakId: null,
    password: null,
    temporaryPassword: false,
    profile: {
      id: null,
      address: {
        id: null
      },
    },
    createdAt: null,
    updatedAt: null,
    locale: 'en',
    enabled: false,
    emailVerified: false,
    type: null,
    legalEntityList: [],
    hotelList: [],
    roomList: []
  };

  localeList = new Array<Locale>();

  countryList = new Array<Country>();
  countryListLoading: boolean;

  userTypeList = new Array<UserType>();
  userTypeListLoading: boolean;

  dropdownEnabled = true;
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  });

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[0];

  roomPermissions: TreeviewItem[];
  legalEntityPermissions: TreeviewItem[];
  hotelPermissions: TreeviewItem[];

  constructor(private userService: UserService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private roleService: PermissionMapService,
              private userTypeService: UserTypeService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {

    this.roleService.getRoles().then(value => {
      this.legalEntityPermissions = [new TreeviewItem(value.data.legalEntityList)];
      this.hotelPermissions = [new TreeviewItem(value.data.hotelList)];
      this.roomPermissions = [new TreeviewItem(value.data.roomList)];
    });

    this.localeList = [
      {
        iso: 'de',
        name: 'Deutsch'
      },
      {
        iso: 'en',
        name: 'English'
      }
    ];

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    this.userTypeService.getList().subscribe(response => {
      this.userTypeList = response.data;
    });
  }

  customSearchFn(term: string, item: LegalEntity) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onFormSubmit(value) {

    const that = this;

    this.submitEnabled = false;

    value.legalEntityList = this.user.legalEntityList;
    value.hotelList = this.user.hotelList;
    value.roomList = this.user.roomList;

    this.userService.create(value).subscribe(resp => {

      if (resp.status === 'success') {
        that.user = {
          id: null,
          email: null,
          username: null,
          roleList: null,
          keycloakId: null,
          password: null,
          temporaryPassword: false,
          profile: {
            id: null,
            address: {
              id: null
            },
          },
          createdAt: null,
          updatedAt: null,
          locale: 'en',
          enabled: false,
          emailVerified: false,
          type: null,
          legalEntityList: [],
          hotelList: [],
          roomList: []
        };

        this.toastr.success('User has been successfully created!');

      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  log() {
    console.dir(this.submitEnabled);
    console.dir('click protection');
  }

  onFilterChange(value: string) {

  }

  onLegalEntityPermissionSelectChange($event: any[]) {
    this.user.legalEntityList = $event;
  }

  onHotelPermissionSelectChange($event: any[]) {
    this.user.hotelList = $event;
  }

  onRoomPermissionSelectChange($event: any[]) {
    this.user.roomList = $event;
  }

  isGranted(role: string) {
    return this.authenticationService.isGranted(role);
  }
}
