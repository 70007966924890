import {Component, Input, OnInit} from '@angular/core';
import {Table} from '../../../../../models/table';
import {Page} from '../../../../../models/page';
import {Room} from '../../../../../models/room';
import {ActivatedRoute} from '@angular/router';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {HotelService} from '../../../../../services/hotel/hotel.service';
import {RoomService} from '../../../../../services/room/room.service';
import {RoomType} from '../../../../../models/room-type';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {Floor} from '../../../../../models/floor';
import {FloorService} from '../../../../../services/floor/floor.service';

@Component({
  selector: 'app-hotel-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.css']
})
export class RoomListComponent implements OnInit {

  @Input() floor = null;

  range: string;
  typeId = 0;
  floorId: number;
  createWaiting = false;
  roomTypeList: RoomType[];
  hotelId: string;

  table: Table = {
    columnMode: ColumnMode.standard,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'VIP Status', prop: 'vipStatus'},
      {name: 'DND', prop: 'doNotDisturb'},
    ],
    rowList: new Array<Room>()
  };

  createModalOptions = {
    title: 'Create Room',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {

        this.createWaiting = true;
        this.create(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  floorList: Array<Floor>;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private hotelService: HotelService,
              private roomService: RoomService,
              private floorService: FloorService,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.getFloorList();
    this.roomTypeList = [
      {id: 1, name: 'Guest Room'},
      {id: 2, name: 'Conference Room'},
      {id: 3, name: 'Public'},
    ];
    this.getPage(0);
    this.hotelId = this.route.snapshot.paramMap.get('id');
  }

  delete(rowList: Array<Room>) {

    const that = this;

    rowList.forEach(function (row) {
      that.roomService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.roomService.create(this.route.snapshot.paramMap.get('id'), this.range, this.typeId, this.floorId).subscribe(dataResponse => {

        resolve();
        this.createWaiting = false;
        this.getPage(0);
        this.range = '';
        this.floorId = 0;
        this.typeId = 0;
        this.getPage(0);
        /*
        this.toastr.error(dataResponse.message);

          resolve();
          this.createWaiting = false;

          if(dataResponse.status === 1) {
            this.range = '';
            this.floorId = 0;
            this.typeId = 0;
            this.getPage(0);
          }
    */
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    if (this.floor) {
      this.hotelService.getRoomListByFloor(this.route.snapshot.paramMap.get('id'), this.floor, this.table.page).subscribe(dataResponse => {
        this.table.page = dataResponse.page;
        const data = dataResponse.data;
        this.table.rowList = [...data];
        this.table.loading = false;
      });
    } else {
      this.hotelService.getRoomList(this.route.snapshot.paramMap.get('id'), this.table.page).subscribe(dataResponse => {
        this.table.page = dataResponse.page;
        const data = dataResponse.data;
        this.table.rowList = [...data];
        this.table.loading = false;
      });
    }
  }

  onBeforeOpen($event: BeforeOpenEvent) {
    this.typeId = 0;
  }

  private getFloorList() {
    this.floorService.floorList.subscribe(value => {
      this.floorList = value;
    });
  }

  roomRightText(type: string) {

    if (type !== '') {
      return this.roomService.getRight(type);
    }

    return '-';
  }
}
