import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {
  ListUserComponent,
  ListLegalEntityComponent,
  ListDeviceComponent,
  ListHotelComponent,
  AddLegalEntityComponent,
  EditLegalEntityComponent,
  AddHotelComponent,
  EditHotelComponent,
  EditUserComponent,
  AddUserComponent,
  DashboardComponent,
  ViewLegalEntityComponent,
  ViewHotelComponent,
  ListModuleComponent,
  ViewUserComponent,
  EditModuleComponent,
  AddModuleComponent,
  LegalEntityComponent,
  HotelComponent,
  UserComponent,
  ModuleComponent,
  ViewModuleComponent,
  ViewInstanceComponent
} from './components';
import {ErrorComponent} from './components/error/error.component';
import {RoomViewComponent} from './components/hotel/view/tabs/room-view/room-view.component';
import {MeComponent} from './components/me/me.component';
import {ListInstanceComponent} from './components/instance/list/list-instance.component';
import {ListActivitiesComponent} from './components/activities/list/list-activities.component';
import {KnownIssuesComponent} from './components/known-issues/known-issues.component';
import {ManagementComponent} from './components/management/management.component';
import {ViewElectroDeviceInventoryComponent} from './components/hotel/view/tabs/view-instance/electro-device-inventory/view/view-electro-device-inventory.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'me',
    component: MeComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'My Profile'}
  },
  {
    path: 'management',
    component: ManagementComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'legal-entities',
    component: LegalEntityComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Legal Entities'},
    children: [
      {
        path: '',
        component: ListLegalEntityComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'create',
        component: AddLegalEntityComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Create'}
      },
      {
        path: ':id',
        component: ViewLegalEntityComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'View'}
      },
      {
        path: ':id/edit',
        component: EditLegalEntityComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit'}
      }
    ]
  },
  {
    path: 'hotels',
    component: HotelComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Hotels'},
    children: [
      {
        path: '',
        component: ListHotelComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'List'}
      },
      {
        path: 'create',
        component: AddHotelComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Create'}
      },
      {
        path: ':id',
        component: ViewHotelComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Hotel View'}
      },
      {
        path: ':id/instances/:instanceId',
        component: ViewInstanceComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Instance View'}
      },
      {
        path: ':id/instances/:instanceId/electro-device-inventories/:inventoryId',
        component: ViewElectroDeviceInventoryComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Bestandverzeichnis'},
      },
      {
        path: ':id/rooms/:roomId',
        component: RoomViewComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Room View'},
      },
      {
        path: ':id/edit',
        component: EditHotelComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit'}
      },
    ]
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Users'},
    children: [
      {
        path: '',
        component: ListUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'List'}
      },
      {
        path: 'create',
        component: AddUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Create'}
      },
      {
        path: ':id',
        component: ViewUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'View'}
      },
      {
        path: ':id/edit',
        component: EditUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit'}
      },
    ]
  },
  {
    path: 'devices',
    component: ListDeviceComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Devices'}
  },
  {
    path: 'instances',
    component: ListInstanceComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Instances'}
  },
  {
    path: 'activities',
    component: ListActivitiesComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Activities'}
  },
  {
    path: 'logs',
    component: ListActivitiesComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'System Logs'}
  },
  {
    path: 'known-issues',
    component: KnownIssuesComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Known Issues'}
  },
  {
    path: 'modules',
    component: ModuleComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Modules'},
    children: [
      {
        path: '',
        component: ListModuleComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'List'}
      },
      {
        path: ':id/edit',
        component: EditModuleComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit'}
      },
      {
        path: 'create',
        component: AddModuleComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Create'}
      },
      {
        path: ':id',
        component: ViewModuleComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'View'}
      }
    ]
  },
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
