import {Component, OnInit} from '@angular/core';
import {VoipPhoneMapping} from '../../../../../../../models/voip-phone-mapping';
import {Table} from '../../../../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Page} from '../../../../../../../models/page';
import {VoipPhoneService} from '../../../../../../../services/voip/phone/voip-phone.service';
import {VoipPhoneMappingService} from '../../../../../../../services/voip/phone-mapping/voip-phone-mapping.service';
import {HotelService} from '../../../../../../../services/hotel/hotel.service';
@Component({
  selector: 'app-phone-mapping',
  templateUrl: './phone-mapping.component.html',
  styleUrls: ['./phone-mapping.component.css']
})
export class PhoneMappingComponent implements OnInit {

  currentInstanceId = null;
  currentHotelId = null;

  newObject: VoipPhoneMapping = new VoipPhoneMapping();
  phoneList = [];
  roomList = [];

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: {sortColumn: 'id', sortOrder: 'ASC', totalElements: 0, totalPages: 0, size: 10, pageNumber: 0},
    loading: false,
    columnList: [
      {name: 'Room', prop: 'room.number'},
      {name: 'Phone Name', prop: 'phone.name'},
      {name: 'Phone Mac Address', prop: 'phone.identifier'},
      {name: 'Phone Extension', prop: 'phone.extension'},
      {name: 'Phone Vendor Product', prop: 'phone.vendorProduct'}
    ],
    rowList: new Array<VoipPhoneMapping>()
  };

  createWaiting = false;

  createModalOptions = {
    title: 'Register Phone to Room',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {

        this.createWaiting = true;
        this.create(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  // private SwalError: string;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private voipPhoneMappingService: VoipPhoneMappingService,
              private toastr: ToastrService,
              private voipPhoneService: VoipPhoneService,
              private hotelService: HotelService) {
  }

  ngOnInit() {

    this.currentInstanceId = this.route.snapshot.paramMap.get('instanceId');
    this.currentHotelId = this.route.snapshot.paramMap.get('id');
    this.getPage(0);
    this.getPhoneList();
    this.getRoomList();

  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  delete(rowList: Array<VoipPhoneMapping>) {

    const that = this;

    rowList.forEach(function (row) {
      that.voipPhoneMappingService.delete(that.currentInstanceId, row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.voipPhoneMappingService.getList(this.currentInstanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.voipPhoneMappingService.create(this.currentInstanceId, this.newObject).subscribe(dataResponse => {

        if (dataResponse.status === 'success') {
          this.newObject = new VoipPhoneMapping();
          resolve();
          this.getPage(0);
        }

        if (dataResponse.status === 'error') {
          resolve();
          this.toastr.error(dataResponse.message);
        }

        this.createWaiting = false;
        this.getPage(0);

      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  onBeforeOpen($event: BeforeOpenEvent) {
    this.newObject = new VoipPhoneMapping();
  }

  private getPhoneList() {

    const page: Page = {
      pageNumber: 0,
      totalPages: 0,
      size: 100,
      totalElements: 0,
      sortOrder: 'ASC',
      sortColumn: 'name'
    };

    this.voipPhoneService.getAllList(this.currentInstanceId, page).subscribe(value => {
      this.phoneList = [...value.data];
    });
  }

  private getRoomList() {

    const page: Page = {
      pageNumber: 0,
      totalPages: 0,
      size: 2000,
      totalElements: 0,
      sortOrder: 'ASC',
      sortColumn: 'number'
    };

    this.hotelService.getRoomList(this.route.snapshot.paramMap.get('id'), page).subscribe(value => {
      this.roomList = [...value.data];
    });
  }
}
