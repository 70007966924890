import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DeviceManufacturer} from '../../../models/device-manufacturer';
import {DataListResponse} from '../../../models/data-list-response';
import {HelperService} from '../../helper.service';
import {DataResponse} from '../../../models/data-response';
import {Page} from '../../../models/page';


@Injectable({
  providedIn: 'root'
})
export class DeviceManufacturerService {

  endPoint = `/management/device-manufacturers`;

  list: Observable<DataListResponse<DeviceManufacturer>> = null;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<DeviceManufacturer>> {
    return this.http.get<DataListResponse<DeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(id): Observable<DataResponse<DeviceManufacturer>> {
    return this.http.get<DataResponse<DeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(value: DeviceManufacturer): Observable<DataListResponse<DeviceManufacturer>> {
    return this.http.post<DataListResponse<DeviceManufacturer>>(HelperService.getAPIUrl(this.endPoint), value);
  }

  update(value: DeviceManufacturer): Observable<DataListResponse<DeviceManufacturer>> {
    return this.http.put<DataListResponse<DeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/${value.id}`, value);
  }

  delete(id: number): Observable<DataListResponse<DeviceManufacturer>> {
    return this.http.delete<DataListResponse<DeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
