import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';
import {Room} from '../../models/room';


enum RoomRights {
  MU = 'Unlock Minibar',
  MN = 'Minibar normal vending',
  ML = 'Lock Minibar',
  TU = 'Unlimited pay channels',
  TM = 'No Pay movies',
  TX = 'No Adult movies',
  TN = 'No TV rights',
  VA = 'View bill & remote c/o',
  VB = 'Only view bill',
  VN = 'No video rights'
}
/*
enum AnswerStatus {
  AA = 'Virtual Number already assigned',
  AN = 'Virtual Number not found',
  BM = 'Balance mismatch',
  BY = 'Telephone / Encoder Busy',
  CD = 'Check-out date is not today',
  CO = 'Posting denied because overwriting the CreditLimit is not allowed',
  DE = 'Wakeup/Key has been deleted',
  DM = 'Sum of subtotals doesn\'t match TotalAmount',
  DN = 'Request denied',
  FX = 'Guest not allowed this feature',
  IA = 'Invalid account',
}
*/

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  endPoint = `/hotels`;

  constructor(private http: HttpClient) {
  }

  getList(id: string, page: Page): Observable<DataListResponse<Room>> {
    return this.http.get<DataListResponse<Room>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}/rooms`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(hotelId, roomId): Observable<DataResponse<Room>> {
    return this.http.get<DataResponse<Room>>(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/rooms/${roomId}`);
  }

  create(hotelId: string, range: string, typeId: number, floorId: number) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/rooms`, {
      range: range,
      typeId: typeId,
      floorId: floorId
    }, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(id: number, value: any): Observable<DataListResponse<Room>> {
    return this.http.put<DataListResponse<Room>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}/edit`, value);
  }

  delete(id: number): Observable<DataListResponse<Room>> {
    return this.http.delete<DataListResponse<Room>>(`${HelperService.getAPIUrl('/rooms')}/${id}`);
  }

  getRight(type: string): RoomRights {

    return RoomRights[type];
  }

  /*
    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
    }*/

}
