import {Component, Input, OnInit} from '@angular/core';
import {Instance} from '../../../../../../../models/instance/instance';
import {DeviceModel} from '../../../../../../../models/device-model';
import {Table} from '../../../../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../../../../../models/page';
import {NetworkDeviceInfo} from '../../../../../../../models/network-device-info';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {DeviceModelService} from '../../../../../../../services/device-model/device-model.service';
import {Room} from '../../../../../../../models/room';
import {OtrumInfoService} from '../../../../../../../services/otrum-info.service';
import {OtrumInfo, OtrumTvProduct} from '../../../../../../../models/otrum-info';
import {DataResponse} from '../../../../../../../models/data-response';

@Component({
  selector: 'app-instance-otrum-info-view',
  templateUrl: './otrum-info.component.html',
  styleUrls: ['./otrum-info.component.css']
})
export class OtrumInfoComponent implements OnInit {

  // @ViewChild('createModal', {read: true, static: false}) private createSwal: SwalComponent;

  @Input() instance: Instance = null;

  tvProductList = [];
  selectedObject: OtrumInfo = null;
  createWaiting = false;
  editWaiting = false;
  newObject: OtrumInfo = new OtrumInfo();
  hotelId: string;

  deviceModelList: DeviceModel[];

  createModalOptions = {
    title: 'Add New Otrum Info',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Create',
    showLoaderOnConfirm: true,
    width: '650px',
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  editModalOptions = {
    title: 'Edit Otrum Info',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Save',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.update(resolve);
      });
    },
    allowOutsideClick: () => !this.editWaiting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Hotel Name', prop: 'hotelName'},
      {name: 'Device', prop: 'deviceModel.name'},
      {name: 'Otrum Product', prop: 'deviceModel.tvProduct.name'},
      {name: 'Enterprise Web URL', prop: 'enterpriseWebUrl'},
    ],
    rowList: new Array<NetworkDeviceInfo>()
  };

  // private SwalError: string;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private otrumInfoService: OtrumInfoService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private deviceModelService: DeviceModelService) {
  }

  ngOnInit() {

    this.tvProductList = [
      {id: 0, name: 'Otrum Enterprise on Premise'} as OtrumTvProduct,
      {id: 1, name: 'Otrum Ctrl'} as OtrumTvProduct,
      {id: 2, name: 'Otrum Start'} as OtrumTvProduct,
      {id: 3, name: 'Otrum Enterprise Cloud'} as OtrumTvProduct,
      {id: 4, name: 'Otrum Evolution'} as OtrumTvProduct,
    ];

    this.getOtrumList();
    this.getPage(0);
    this.hotelId = this.route.snapshot.paramMap.get('id');
  }

  update(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.editWaiting = true;

    // if (this.selectedObject.deviceModel === null) {
    //   this.toastr.error('Please choose a device model');
    // }

    this.otrumInfoService.update(this.selectedObject, this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('instanceId'))
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.editWaiting = false;
        },
        error => {
          this.editWaiting = false;
        });

    this.editWaiting = false;
  }

  private getOtrumList() {

    this.deviceModelService.getOtrumList(this.route.snapshot.paramMap.get('id'), this.table.page).subscribe(dataResponse => {
      const data = dataResponse.data;
      this.deviceModelList = [...data];
    });
  }

  delete(rowList: Array<Room>) {

    const that = this;

    rowList.forEach(function (row) {
      that.otrumInfoService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });
    });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {


    // if (this.newObject.deviceModel === undefined) {
    // this.toastr.error('Please choose a device model');
    //  return;
    // }

    this.createWaiting = true;

    this.otrumInfoService.create(
      this.newObject,
      this.route.snapshot.paramMap.get('id'),
      this.route.snapshot.paramMap.get('instanceId')).subscribe((dataResponse: DataResponse<OtrumInfo>) => {

      if (dataResponse.status === 'success') {
        this.newObject = new OtrumInfo();
        resolve();
        this.getPage(0);
      }

      if (dataResponse.status === 'error') {
        resolve();
        this.toastr.error(dataResponse.message);
      }

      this.createWaiting = false;

    });
  }

  onActivate(event: any) {

  }

  onSelect(event: any) {

  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const hotelId = this.route.snapshot.paramMap.get('id');
    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.otrumInfoService.getList(hotelId, instanceId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: string = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
    // this.newObject = new OtrumInfo();
  }

  edit(row: OtrumInfo) {
    this.selectedObject = row;
  }
}
