import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../../models/data-list-response';
import {HelperService} from '../../helper.service';
import {DataResponse} from '../../../models/data-response';
import {VoipCountryZone} from '../../../models/voip-country-zone';

@Injectable({
  providedIn: 'root'
})
export class VoipCountryZoneService {

  endPoint = `/instances`;

  constructor(private http: HttpClient) {
  }

  getList(instanceId: string, page: Page): Observable<DataListResponse<VoipCountryZone>> {
    return this.http.get<DataListResponse<VoipCountryZone>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-country-zones`,
      {
        params: new HttpParams()
          .set('instanceId', instanceId)
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  get(hotelId, roomId): Observable<DataResponse<VoipCountryZone>> {
    return this.http.get<DataResponse<VoipCountryZone>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${hotelId}/voip-country-zones/${roomId}`
    );
  }

  create(instanceId: string, voipCountryZone: VoipCountryZone) {
    return this.http.post(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-country-zones`, voipCountryZone, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }
    );
  }

  delete(instanceId: string, id: number): Observable<DataListResponse<VoipCountryZone>> {
    return this.http.delete<DataListResponse<VoipCountryZone>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${instanceId}/voip-country-zones/${id}`
    );
  }
}
