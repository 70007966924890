import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.css']
})
export class AddModuleComponent implements OnInit {

  constructor() {
  }

  info = 'Cannot connect to the RMQ Server';

  ngOnInit() {
  }

}
