import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {Subject} from 'rxjs';
import {DeviceManufacturer} from '../../models/device-manufacturer';
import {Page} from '../../models/page';

@Injectable({
  providedIn: 'root'
})
export class DeviceManufacturerService {

  endPoint = `/device-manufacturers`;

  list:  Subject<DeviceManufacturer[]> = new Subject<DeviceManufacturer[]>();

  constructor(private http: HttpClient) {
  }

  getList(id: string, page: Page) {
    this.http.get<DataListResponse<DeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    }).subscribe(dataResponse => {
      console.dir(dataResponse);
      this.list.next(dataResponse.data);
    });
  }
}
